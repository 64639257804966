.awardDialog {
  .ant-modal {
    width: 513px !important;
    height: 488px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FECE1E;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
    margin-top: 100px;
  }
  .ant-modal-content {
    width: 513px;
    height: 488px;
    background: none;
  }
  .ant-modal-body {
    width: 513px;
    height: 488px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #FECE1E;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .awardContent {
    height: 100%;
    width: 100%;
    .top {
      height: 160px;
      width: 389px;
      background: url('../../../assets/images/home/christmasTop.png') no-repeat;
      background-size: 389px 160px;
      transform: translateY(-130px);
      margin: 0 auto;
    }
    .box {
      width: 260px;
      height: 260px;
      background: url('../../../assets/images/home/christmasBox.png') no-repeat;
      background-size: 260px 260px;
      margin: 0 auto;
      margin-top: -100px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 226px;
        width: 226px;
        border: 3px solid #AB1B23;
        border-radius: 10px;
      }
    }
    .view {
      text-align: center;
      color: #FFFFFF;
      font-weight: 400;
      font-size: 20px;
      padding: 20px 0;
      span {
        color: #FFB960;
        padding-left: 18px;
        cursor: pointer;
      }
    }
    .btn {
      width: 286px;
      height: 50px;
      background: linear-gradient(119.2deg, #FFFFFF 13.43%, #FFB960 81.68%);
      border-radius: 40px;
      text-align: center;
      line-height: 50px;
      margin: 0 auto;
      color: #623B00;
      font-family: 'SFCompactDisplay';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      margin-top: 0;
      cursor: pointer;
    }
  }
}
