@import '../../px2rem.scss';

.h5RulesAndGame {
  height: px2rem(124px);
  width: 100%;
  margin-bottom: px2rem(20px);
  .dots {
    height: px2rem(10px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding:  px2rem(7px) px2rem(11px) px2rem(19px);
    div {
      height: px2rem(10px);
      width: px2rem(10px);
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: inset -0.1px -0.1px 2px rgba(255, 255, 255, 0.25), inset 0.1px 0.1px 2px rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(px2rem(10px));
    }
  }
  .rulesAndGameBtns {
    height: px2rem(60px);
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    .rulesBtns {
      height: px2rem(72px);
      .pipe {
        width: px2rem(23px);
        height: px2rem(8px);
        margin-top: px2rem(20px);
      }
    }
    .GameBtns {
      height: px2rem(72px);
      .pipe {
        width: px2rem(23px);
        height: px2rem(8px);
        margin-top: px2rem(20px);
      }
    }
    .rules {
      width: px2rem(160px);
      height: px2rem(72px);
    }
  }
  // 48px
  .long {
    width: px2rem(300px);
    height: px2rem(33px);
    display: block;
    margin: 0 auto;
  }
}