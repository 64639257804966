.collectionDialog {
  .how_to_sell {
    margin-left: 20px;
    font-family: "SFCompactDisplayBlod";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    /* identical to box height, or 89% */

    text-align: center;

    color: #fece1e;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 40px;
    top: 64px;
    img {
      width: 17px;
      height: 17px;
      margin-right: 6px;
    }
  }
  .how_to_sell:hover {
    color: #fece1e;
  }
  .ant-modal {
    width: 1068px !important;
    height: 598px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #fece1e;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
  }
  .ant-modal-content {
    width: 1068px;
    height: 598px;
    background: none;
  }
  .ant-modal-body {
    width: 1068px;
    height: 598px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #fece1e;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .dialogContent {
    height: 100%;
    width: 100%;
    .headerBg {
      height: 57px;
      width: 338px;
      margin: -50px 0 0 340px;
      background: url("../../../assets/images/home/mint_BT.png") no-repeat;
      background-size: 338px 57px;
      float: left;
    }
    .dialogHeader {
      float: left;
      font-family: "SFCompactDisplayBlod";
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      text-transform: uppercase;
      color: #623b00;
      z-index: 20;
      margin: -42px 0 0 -256px;
    }
    .price {
      font-family: "SFCompactDisplayLight";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #fece1e;
      position: absolute;
      top: 54px;
      left: 50%;
      transform: translate(-50%);
      span {
        font-size: 26px;
        font-weight: 600;
      }
    }
    .tabs {
      width: 187px;
      height: 39px;
      border: 1px solid #fff9e8;
      border-radius: 10px;
      position: absolute;
      top: 104px;
      left: 452px;
      display: flex;
      justify-content: space-between;
      .listCss {
        height: 39px;
        width: 93px;
        text-align: center;
        line-height: 35px;
        color: #fff9e8;
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        cursor: pointer;
      }
      .activeCss {
        height: 39px;
        width: 93px;
        line-height: 35px;
        text-align: center;
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid #fdcc05;
        box-shadow: inset 0px 1px 10px rgba(253, 204, 5, 0.3), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(2px);
        border-radius: 8px;
        color: #fdcc05;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .historyBtn {
      width: 100px;
      height: 40px;
      border: 1px solid #fece1e;
      border-radius: 32px;
      text-align: center;
      line-height: 40px;
      color: #fece1e;
      font-family: "SFCompactDisplayLight";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      position: absolute;
      right: 102px;
      top: 56px;
    }
    .hm {
      height: 300px;
      width: 100%;
      position: absolute;
      top: 113px;
      padding: 0 36px 0 50px;
      overflow: hidden;
      z-index: 11;
      .noData {
        padding: 120px 0 0 320px;
        font-size: 20px;
        font-weight: 700;
        font-family: "SFCompactDisplayLight";
        color: #fff9e8;
      }
      .data_loading {
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 458px;
          margin-top: 152px;
          margin-bottom: 26px;
        }
      }
      .noData1 {
        padding: 120px 0 0 420px;
        font-size: 20px;
        font-weight: 700;
        font-family: "SFCompactDisplayLight";
        color: #fff9e8;
      }
      .refresh1 {
        font-size: 24px;
        color: #fff9e8;
        margin: 24px 0 0 450px;
      }
      .hmList {
        width: 100px;
        height: 150px;
        float: left;
        margin-right: 18px;
        position: relative;
        .bgImg {
          width: 70px;
          height: 70px;
          float: left;
          position: absolute;
          left: 15px;
          z-index: 1;
          // cursor: pointer;
        }
        .hmImg {
          height: 46px;
          width: 46px;
          border-radius: 6px;
          margin: 0 auto;
          margin-top: 13px;
          margin-left: 27px;
        }
        .hmName {
          text-transform: uppercase;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #fff9e8;
          text-align: center;
          padding-top: 12px;
        }
        .sell {
          width: 90px;
          height: 38px;
          text-align: center;
          font-size: 12px;
          color: #fdcc05;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          cursor: pointer;
          margin: 6px 0 0 6px;
          background: rgba(253, 204, 5, 0.2);
          border: 1px dashed #fece1e;
          border-radius: 4px;
        }
        .selectImg {
          position: absolute;
          right: 14px;
          top: 44px;
          width: 20px;
          height: 20px;
          z-index: 60;
        }
        .listingDiv {
          position: absolute;
          background: rgba(0, 0, 0, 0.6);
          right: 27px;
          top: 41px;
          width: 46px;
          height: 18px;
          z-index: 60;
          color: #fff;
          text-align: center;
          line-height: 18px;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          font-size: 12px;
        }
      }
      .mhList {
        width: 100px;
        height: 150px;
        float: left;
        margin-right: 18px;
        background: url("../../../assets/images/result/two.png") no-repeat;
        background-size: 70px 70px;
        background-position-x: 14px;
        position: relative;
        .hmImg {
          height: 46px;
          width: 46px;
          border-radius: 6px;
          margin: 0 auto;
          margin-top: 12px;
          margin-left: 26px;
        }
        .hmName {
          text-transform: uppercase;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #fff9e8;
          text-align: center;
          padding-top: 14px;
        }
        .sell {
          width: 90px;
          height: 38px;
          text-align: center;
          font-size: 12px;
          color: #fff9e8;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          cursor: pointer;
          margin: 6px 0 0 6px;
          border: 0.5px dashed #fff9e8;
          border-radius: 4px;
        }
        .selectImg {
          position: absolute;
          right: 12px;
          top: 64px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .data_loading_hm {
      height: inherit;
      position: inherit;
      padding: 0;
    }
    .pagination {
      position: absolute;
      height: 70px;
      width: 1052px;
      bottom: 110px;
      text-align: center;
      .ant-pagination-prev,
      .ant-pagination-next,
      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        height: 24px;
      }
      .nextBtn {
        width: 96px;
        height: 30px;
        left: 556px;
        top: 683px;
        border: 1px solid #fff9e8;
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #fff9e8;
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        margin-top: -3px;
      }
      .ant-pagination-item:hover a {
        color: #fdcc05 !important;
        line-height: 30px;
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
      .ant-pagination-item {
        border-radius: 8px;
        border: 1px solid #fff9e8;
        border-radius: 8px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background: none;
        color: #fff9e8;
        a {
          color: #fff9e8;
          line-height: 30px;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
      }
      .ant-pagination-item-active {
        border-radius: 8px;
        backdrop-filter: blur(2px);
        box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
        border: 1px solid #fdcc05;
        background: rgba(255, 255, 255, 0.5);
        width: 30px;
        height: 30px;
        text-align: center;
        a {
          color: #fdcc05;
          line-height: 30px;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
    .recovery {
      position: absolute;
      width: 1052px;
      text-align: center;
      font-size: 18px;
      color: #fece1e;
      bottom: 104px;
      line-height: 21px;
      font-family: "SFCompactDisplayBlod";
      font-weight: 400;
      margin-top: 24px;
      span {
        color: #fd4f05;
        font-weight: 600;
      }
    }
    .sellAndKeepBtns {
      height: 60px;
      width: 1052px;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 30px;
      .sellBtn {
        width: 227px;
        height: 50px;
        background: url('../../../assets/images/result/btn.png');
        background-size: 227px 50px;
        text-align: center;
        line-height: 46px;
        color: #623b00;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        font-family: "SFCompactDisplayBlod";
        margin-right: 40px;
        cursor: pointer;
        img {
          width: 22px;
          height: 22px;
          margin-right: 3px;
          margin-top: -4px;
        }
      }
      .disSellBtn {
        cursor: no-drop;
        opacity: 0.5;
      }
      .keepBtn {
        width: 227px;
        height: 50px;
        background: url('../../../assets/images/result/xian_btn.png');
        background-size: 227px 50px;
        text-align: center;
        line-height: 46px;
        color: #fece1e;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        font-family: "SFCompactDisplayLight";
        margin-left: 40px;
        cursor: pointer;
      }
    }
    .loadIcon {
      font-size: 50px;
      height: 50px;
      color: white;
      position: absolute;
      top: 130px;
      left: 500px;
    }
    .noDataBtns {
      width: 126px;
      height: 40px;
      border: 1px solid #fece1e;
      border-radius: 32px;
      text-align: center;
      font-family: "SFCompactDisplayLight";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 40px;
      color: #fece1e;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
}
