.bnbDialog {
  margin-top: 100px;
  .ant-modal {
    width: 592px !important;
    height: 500px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FECE1E;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
  }
  .ant-modal-content {
    width: 592px;
    height: 500px;
    background: none;
  }
  .ant-modal-body {
    width: 592px;
    height: 500px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #FECE1E;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .bnbContent {
    height: 100%;
    width: 100%;
    .topImg {
      width: 548px;
      height: 124px;
      transform: translateY(-90px);
      margin-left: -10px;
    }
    .title {
      transform: translateY(-90px);
      font-family: 'SFCompactDisplay';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      text-align: center;
      color: #FDCC05;
    }
    .nfc {
      height: 201px;
      width: 201px;
      background: linear-gradient(91.11deg, #F4B84E 0.7%, #FDEFCB 6.8%, #F4BA4E 17.13%, #F7CD7D 50.79%, #F4BB4F 82.46%, #FDF0CD 92.34%, #F4B94E 98.84%);
      border-radius: 30px;
      margin: 0 auto;
      transform: translateY(-66px);
      display: flex;
      justify-content: center;
      align-items: center;
      .nfcImg {
        width: 168px;
        height: 168px;
        border: 2px solid #F1AF35;
        border-radius: 20px;
      }
    }
    .bnbTips {
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: #FDCC05;
      transform: translateY(-46px);
    }
    .view {
      width: 180px;
      height: 50px;
      background: linear-gradient(269.93deg, #FDCC05 0.93%, #FEEF67 127.01%);
      box-shadow: 0px 4px 20px rgba(254, 206, 9, 0.3);
      border-radius: 40.704px;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 50px;
      text-align: center;
      color: #623B00;
      margin: 0 auto;
      transform: translateY(-20px);
      cursor: pointer;
    }
  }
}