 
@import '../../../px2rem.scss';

.navbar {
  height: px2remToPc(70px);
  width: calc(100% - px2remToPc(17px));
  padding: 0 px2remToPc(100px);
  background: rgb(18, 14, 29);
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  min-width: px2remToPc(1440px);
  .right-menu {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    // width: calc(100% - px2remToPc(200px));
    width:100%;
    padding: 0 px2remToPc(30px);
    display: flex;
    justify-content: space-between;
    max-width: px2remToPc(1440px);
    .logo {
      width: px2remToPc(350px);
      color: white;
      margin-top: px2remToPc(10px);
      font-size: px2remToPc(12px);
      padding-left: px2remToPc(6px);
      float: left;
      cursor: pointer;
      // position: absolute;
      // left: 0;
      .logo1 {
        height: px2remToPc(40px);
        width: px2remToPc(125px);
        float: left;
        margin-top: px2remToPc(4px);
      }
      .logox {
        float: left;
        padding: 0 px2remToPc(10px) 0 0px;
        line-height: px2remToPc(46px);
      }
      .logo2 {
        height: px2remToPc(40px);
        width: px2remToPc(174px);
        float: left;
        margin-top: px2remToPc(4px);
      }
    }
    .tabs {
      width: px2remToPc(500px);
      height: 100%;
      color: white;
      display: flex;
      justify-content: center;
      position: relative;
      li {
        float: left;
        width: px2remToPc(70px);
        text-align: center;
        font-size: 16px;
        font-family: "SFCompactDisplay";
        font-style: normal;
        font-weight: 600;
      }
      .lucky {
        min-width: px2remToPc(130px);
        .shou {
          height: px2remToPc(20px);
          width: px2remToPc(20px);
          margin: px2remToPc(-2px) px2remToPc(4px) 0 0;
        }
      }
      .listCss{
        width: px2remToPc(500px);
        float: left;
        text-align: center;
        font-family: "SFCompactDisplay";
        font-style: normal;
        font-weight: 600;
        font-size: px2remToPc(16px);
        line-height: px2remToPc(70px);
        >li {
          cursor: pointer;
        }
      }
      .activeCss {
        width: px2remToPc(70px);
        color: #fece1e;
      }
      .liItem {
        width: px2remToPc(70px);
        color: white;
      }
      span {
        position: absolute;
      }
      .lineCss {
        width: px2remToPc(32px);
        height: px2remToPc(3px);
        background: #fece1e;
        position: absolute;
        bottom: 0;
        left: px2remToPc(18px);
        border-radius: 999rem;
        color: #FECE1E;
      }
      .tradLine {
        width: px2remToPc(32px);
        height: px2remToPc(3px);
        background: #FECE1E;
        position: absolute;
        bottom: 0;
        left: 90px;
        border-radius: 999rem;
        color: #fece1e;
      }
    }
    &:focus {
      outline: none;
    }
    .right-menu-item {
      float: right;
      // display: inline-block;
      padding: 0 px2remToPc(8px);
      height: 100%;
      font-size: px2remToPc(18px);
      color: #5a5e66;
      vertical-align: text-bottom;
      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }
    .avatar-wrapper {
      position: relative;
      cursor: pointer;
      align-items: center;
      display: flex;
      .vipImg {
        width: px2remToPc(29px);
        height: px2remToPc(25px);
        margin-left: px2remToPc(12px);
      }
      .wallet {
        height: px2remToPc(32px);
        width: px2remToPc(122px);
        background: linear-gradient(269.93deg, #fdcc05 0.93%, #feef67 127.01%);
        box-shadow: 0px px2remToPc(4px) px2remToPc(16px) rgba(254, 206, 9, 0.3);
        border-radius: 999rem;
        // text-align: center;
        line-height: px2remToPc(32px);
        color: #120e1d;
        font-weight: 700;
        margin-top: px2remToPc(20px);
        font-size: px2remToPc(14px);
        font-family: "SFCompactDisplayBlod";
        .step1 {
          height: px2remToPc(32px);
          width: px2remToPc(122px);
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: px2remToPc(24px);
            height: px2remToPc(24px);
            margin-right: px2remToPc(4px);
          }
        }
        .step2 {
          height: px2remToPc(32px);
          width: px2remToPc(122px);
          text-align: center;
          padding: 0 px2remToPc(12px);
          float: left;
          i {
            float: right;
            margin-top: px2remToPc(9px);
            font-weight: 700;
          }
        }
      }
      .user-avatar {
        cursor: pointer;
        width: px2remToPc(40px);
        height: px2remToPc(40px);
        border-radius: px2remToPc(10px);
      }
      .el-icon-caret-bottom {
        cursor: pointer;
        position: absolute;
        right: px2remToPc(-20px);
        top: 0px;
        font-size: px2remToPc(50px);
      }
      .dropdown-header {
        color: white;
      }
      .dropdownStyle {
        // width: '255px',
        //   height: '184px',
        //   position: 'absolute',
        //   border: '1px solid #FECE1E',
        //   background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 15.93%, rgba(255, 255, 255, 0.3) 100%)',
        //   boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3), inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4)',
        //   backdropFilter: 'blur(45px)',
        //   padding: '0 20px',
        //   borderRadius: '20px',
        width: px2remToPc(255px);
        height: px2remToPc(184px);
        border-radius: px2remToPc(20px);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 15.93%, rgba(255, 255, 255, 0.3) 100%) !important;
        border: 1px solid #fece1e !important;
        box-shadow: 0px px2remToPc(4px) px2remToPc(20px) rgba(0, 0, 0, 0.3), inset 0px -4px 60px rgba(166, 139, 197, 0.4),
          inset 0px px2remToPc(4px) px2remToPc(20px) rgba(166, 139, 197, 0.4) !important;
        backdrop-filter: blur(px2remToPc(45px)) !important;
        padding: 0 px2remToPc(20px) !important;
      }
    }
  }
}
