@import "../../px2rem.scss";

.mintBody {
    flex: 1;
    display: flex;
    .fixed {
        width: px2rem(62px);
        .tableHead {
            background: linear-gradient(to right, transparent 0%, rgba(255, 252, 232, 0.1) 100%);
        }
        .tableBody {
            width: px2rem(62px);
        }
        .col.col1 {
            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 400;
            font-size: px2rem(12px);
            color: #fece1e;
        }
    }

    .fixed.soll {
        .tableHead {
            background: transparent;
        }
        background: #37364d;
        box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.1);
    }

    .h5MintTable {
        // width: 100vw;
        overflow: auto;
        .tableHead {
            width: px2rem(450px);
            background: rgba(255, 252, 232, 0.1);
            .col {
                font-weight: 700;
                font-size: px2rem(14px);
            }
        }
        .tableBody {
            width: px2rem(450px);
            .table-tips {
                margin: auto;
                font-family: "SFCompactDisplayLight";
                font-style: normal;
                font-weight: 400;
                font-size: px2rem(12px);
                margin:px2rem(50px) 0 px2rem(20px) px2rem(110px);
                /* identical to box height, or 417% */ 
                color: #fff9e8;
            }
        }
    }

    .col {
        height: px2rem(40px);
        // font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(12px);
        color: #fff9e8;
        display: inline-block;
        line-height: px2rem(40px);
        text-align: center;
    }

    .col.col1 {
        min-width: px2rem(62px);
    }
    .col.col2 {
        min-width: px2rem(62px);
    }
    .col.col3 {
        min-width: px2rem(81px);
    }
    .col.col4 {
        min-width: px2rem(110px);
    }
    .col.col5 {
        width: px2rem(155px);
    }
    .col.col6 {
        min-width: px2rem(162px);
    }
}
