@import '../../../px2rem.scss'; 

.elementPanel {
    background-image: url("../../../assets/images/h5/element.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: px2rem(169px);
    width: 100%;

    .hyper {
        font-family: "WhiteNTBold";
        font-style: normal;
        font-weight: 700;
        font-size: px2rem(30px);
        line-height: px2rem(30px);
        text-align: center;
        background: linear-gradient(0deg, #fdcc05 19.79%, #fed467 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        width: px2rem(335px);
        margin: auto;
        padding-top: px2rem(25px);
    }
    .studio {
        height: px2rem(20px);
        margin-top: px2rem(10px);
        background-image: url("../../../assets/images/home/studio.png");
        background-size: px2rem(120px) px2rem(20px);
        background-repeat: no-repeat;
        background-position-x: 50%;

        font-family: "SFCompactDisplayBlod";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(11px);
        line-height: px2rem(20px);
        /* or 182% */

        text-align: center;

        color: #fff9e8;
    }
}

.mask {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #000000;
    opacity: 0.9;
    .maskContent {
        width: 100%;
        color: #FFFFFF;
        position: absolute;
        top: calc(50% - px2rem(150px));
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-size: px2rem(16px);
        .btn {
            display: flex;
            justify-content: center;
            div {
                width: px2rem(59px);
                height: px2rem(59px);
                border-radius: 50%;
                background: #3d6bf5;
                margin-top: px2rem(24px);
                .img4 {
                    width: px2rem(38px);
                    height: px2rem(36px);
                    margin-top: px2rem(10px);
                }
                .img3 {
                   width: px2rem(33px);
                   height: px2rem(30px);
                   margin-top: px2rem(12px);
                }
            }
            .one {
                margin-right: px2rem(40px);
            }
        }
        .back {
            width: px2rem(146px);
            height: px2rem(50px);
            border-radius: 999rem;
            border: 1px solid #FECE1E;
            margin: 0 auto;
            line-height: px2rem(46px);
            color: #FECE1E;
            font-size: px2rem(20px);
            margin-top: px2rem(90px);
        }
    }
}
