@import '../../../px2rem.scss';

.mintTwo {
  height: px2remToPc(546px);
  width: px2remToPc(1092px);
  background: url('../../../assets/images/home/choose-bg.png') no-repeat;
  background-size: px2remToPc(1092px) px2remToPc(546px);
  margin: 0 auto;
  margin-top: px2remToPc(-100px);
  .gold1 {
    width: 170px;
    height: 206px;
    background: url("../../../assets/images/home/jb1.png") no-repeat;
    background-size: 170px 206px;
    position: absolute;
    top: 20px;
    right: 100px;
  }
  .gold2 {
    width: 170px;
    height: 206px;
    background: url("../../../assets/images/home/jb1.png") no-repeat;
    background-size: 170px, 206px;
    position: absolute;
    top: px2remToPc(-10px);
    left: px2remToPc(160px);
  }
  .gold3 {
    width: 170px;
    height: 206px;
    background: url("../../../assets/images/home/jb1.png") no-repeat;
    background-size: 170px 206px;
    position: absolute;
    top: px2remToPc(350px);
    left: px2remToPc(110px);
  }
  .inner {
    height: px2remToPc(546px);
    width: px2remToPc(1092px);
    padding-top: px2remToPc(125px);
    .scollTop {
      width: px2remToPc(502px);
      height: px2remToPc(50px);
      background: rgba(20, 16, 30, 0.3);
      border-radius: 6px;
      margin: 0 auto;
      margin-top: px2remToPc(24px);
      line-height: px2remToPc(50px);
      .swiper-container {
        width: px2remToPc(502px);
        height: px2remToPc(50px);
        .swiper-slide {
          width: px2remToPc(502px) !important;
          height: px2remToPc(50px) !important;
          .baby {
            width: px2remToPc(160px);
            height: px2remToPc(50px);
            padding-left: px2remToPc(20px);
            font-family: 'SFCompactDisplayLight';
            color: #FFF9E8;
            font-style: normal;
            font-weight: 400;
            float: left;
            overflow: hidden;
            .beele {
              font-size: px2remToPc(16px);
              color: #FFF9E8;
            }
          }
          .successful {
            font-style: normal;
            font-weight: 400;
            font-size: px2remToPc(12px);
            font-family: 'SFCompactDisplayLight';
            color: #FFF9E8;
            padding-right: px2remToPc(20px);
            .sol {
              color: #FECE1E;
            }
          }
          .prize {
            width: px2remToPc(150px);
            height: px2remToPc(50px);
            padding-right: px2remToPc(20px);
            float: right;
            img {
              height: px2remToPc(30px);
              width: px2remToPc(30px);
              float: left;
              margin-right: px2remToPc(8px);
              margin-top: px2remToPc(10px);
            }
            .ellipsis {
              color: #FFF9E8;
              font-family: 'SFCompactDisplayLight';
              font-style: normal;
              font-weight: 400;
              font-size: px2remToPc(22px);
              width: px2remToPc(24px);
              height: 100%;
              float: left;
              margin-top: px2remToPc(-6px);
            }
          }
        }
      }
    }
    .content {
      width: 100%;
      height: px2remToPc(310px);
      display: flex;
      .left {
        width: px2remToPc(486px);
        height: px2remToPc(300px);
        margin: px2remToPc(10px) 0 0 px2remToPc(182px);
        .drawContent {
          width: px2remToPc(444px);
          height: px2remToPc(260px);
          margin: px2remToPc(-290px) 0 0 px2remToPc(21px);
          display: flex;
          justify-content:center;
          position: relative;
          .card {
            width: px2remToPc(158px);
            height: px2remToPc(228px);
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 0px 0px 20px rgba(253, 204, 5, 0.1), inset 0px -4px 20px rgba(254, 203, 70, 0.2), inset 0px 4px 20px rgba(212, 154, 5, 0.2);
            backdrop-filter: blur(10px);
            border-radius: 10px;
            margin: 0 px2remToPc(23px);
            .cardImg {
              width: px2remToPc(198px);
              height: px2remToPc(268px);
              margin: px2remToPc(-20px) 0 0 px2remToPc(-20px);
            }
            .imgs {
              width: px2remToPc(136px);
              height: px2remToPc(130px);
              margin: px2remToPc(-372px) 0 0 px2remToPc(11px);
              border-radius: px2remToPc(8px);
            }
            .price {
              color: #FECE1E;
              font-size: px2remToPc(26px);
              font-family: 'SFCompactDisplayLight';
              font-style: normal;
              font-weight: 400;
              text-align: center;
              margin-top: px2remToPc(-120px);
              span {
                color: white;
              }
            }
            .bottom {
              text-align: center;
              font-size: px2remToPc(14px);
              font-family: 'SFCompactDisplayLight';
              font-style: normal;
              font-weight: 400;
              color: white;
              margin-top: px2remToPc(-8px);
              img {
                width: px2remToPc(14px);
                height: px2remToPc(14px);
                margin: 0 px2remToPc(6px) 0 0;
              }
            }
          }
          .total {
            position: absolute;
            bottom: px2remToPc(8px);
            left: px2remToPc(20px);
            color: #FFF9E8;
            font-style: normal;
            font-weight: 600;
            font-size: px2remToPc(16px);
            .refresh {
              margin-left: px2remToPc(10px);
              cursor: pointer;
            }
          }
        }
      }
      .right {
        width: px2remToPc(260px);
        height: px2remToPc(300px);
        margin: px2remToPc(10px) 0 0 px2remToPc(40px);
        .mintPrice {
          font-size: px2remToPc(18px);
          color: #FFFFFF;
          text-align: right;
          font-family: 'SFCompactDisplay';
          font-style: normal;
          font-weight: 600;
          span {
            font-weight: 600;
            font-size: px2remToPc(12px);
          }
        }
        .btns {
          height: px2remToPc(130px);
          display: flex;
          justify-content: space-between;
          align-items: center;
          .five {
            height: px2remToPc(130px);
            width: px2remToPc(130px);
            background: rgba(255, 255, 255, 0.3);
            box-shadow: inset -2px 0px 1px rgba(198, 183, 217, 0.25), inset 2px 0px 1px rgba(198, 183, 217, 0.6), inset 0px 2px 1px rgba(204, 204, 204, 0.9), inset 0px -2px 1px rgba(198, 183, 217, 0.6);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .fiveInner {
              height: px2remToPc(110px);
              width: px2remToPc(110px);
              background: linear-gradient(180deg, #FFAD31 0%, #FFDC25 0.01%, #BB8213 100%);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              .last {
                height: px2remToPc(88px);
                width: px2remToPc(88px);
                background: linear-gradient(149.41deg, #FFEC45 15.28%, #FECE1E 86.25%);
                border-radius: 50%;
                color: #623B00;
                font-family: 'SFCompactDisplay';
                font-style: normal;
                font-size: px2remToPc(18px);
                font-weight: 700;
                text-align: center;
                padding-top: px2remToPc(20px);
                div {
                  font-size: px2remToPc(14px);
                  margin-top: px2remToPc(-8px);
                  line-height: px2remToPc(14px);
                }
              }
            }
          }
          .one {
            height: px2remToPc(110px);
            width: px2remToPc(110px);
            background: rgba(255, 255, 255, 0.3);
            box-shadow: inset -2px 0px 1px rgba(198, 183, 217, 0.25), inset 2px 0px 1px rgba(198, 183, 217, 0.6), inset 0px 2px 1px rgba(204, 204, 204, 0.9), inset 0px -2px 1px rgba(198, 183, 217, 0.6);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .oneInner {
              height: px2remToPc(95px);
              width: px2remToPc(95px);
              background: linear-gradient(180deg, #FFAD31 0%, #FFA825 0.01%, #BB6313 100%);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              .last {
                height: px2remToPc(75px);
                width: px2remToPc(75px);
                background: linear-gradient(149.41deg, #FEB82F 15.28%, #FE922F 86.25%);
                border-radius: 50%;
                color: #623B00;
                font-family: 'SFCompactDisplay';
                font-style: normal;
                font-size: px2remToPc(16px);
                font-weight: 700;
                text-align: center;
                padding-top: px2remToPc(16px);
                div {
                  font-size: px2remToPc(12px);
                  margin-top: px2remToPc(-4px);
                  line-height: px2remToPc(12px);
                }
              }
            }
          }
        }
        .bottom {
          width: 100%;
          height: px2remToPc(150px);
          display: flex;
          justify-content: center;
          padding-top: px2remToPc(100px);
          .history {
            width: px2remToPc(97px);
            height: px2remToPc(36px);
            cursor: pointer;
          }
          .rules {
            width: px2remToPc(48px);
            height: px2remToPc(48px);
            background: rgba(255, 255, 255, 0.3);
            box-shadow: inset -2px 0px 1px rgba(198, 183, 217, 0.25), inset 2px 0px 1px rgba(198, 183, 217, 0.6), inset 0px 2px 1px rgba(204, 204, 204, 0.9), inset 0px -2px 1px rgba(198, 183, 217, 0.6);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: px2remToPc(-6px) 0 0 px2remToPc(46px);
            cursor: pointer;
            .rulesInner {
              width: px2remToPc(37px);
              height: px2remToPc(37px);
              background: #FDCC05;
              box-shadow: inset -1px 7px 5px #FFCC69, inset -2px -3px 1px #EE9E03, inset 4px 2px 0px rgba(255, 255, 255, 0.5);
              border-radius: 50%;
              text-align: center;
              color: #623B00;
              font-weight: 600;
              font-size: px2remToPc(12px);
              font-family: 'SFCompactDisplay';
              font-style: normal;
              padding-top: px2remToPc(8px);
            }
          }
        }
      }
    }
  }
}