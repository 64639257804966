@import "../../../px2rem.scss";
.christmasContainer {
    background-image: url("../../../assets/images/h5/xh-bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;

    .christmasTitle {
        // height: px2rem(40px);
        padding-top: px2rem(20px); 
        font-family: "WhiteNTBold"; 
        font-style: normal;
        font-weight: 700;
        font-size: px2rem(26px);
        /* or 115% */

        text-align: center;
        text-transform: capitalize;

        background: linear-gradient(0deg, #fdcc05 19.79%, #fed467 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text; 
        position: relative;

        .xh16 {
            position: absolute;
            width: px2rem(35px);
            height: px2rem(35px);
            top: px2rem(2px);
            left: px2rem(7px);
        }
        .xh17 {
            position: absolute;
            width: px2rem(18px);
            height: px2rem(18px);
            top: px2rem(30px);
            right: px2rem(21px);
        }
    }
    .christmasCenter {
        background-image: url("../../../assets/images/h5/merryChristmas-bg.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        height: px2rem(462px);

        position: relative;
        .topScoll {
            width: px2rem(254px);
            height: px2rem(26px);
            background: rgba(20, 16, 30, 0.6);
            border-radius: px2rem(4px);
            position: absolute;
            top: px2rem(60px);
            left: 50%;
            transform: translateX(-50%);
            line-height: px2rem(26px);
            .h5Swiper-container1 {
                width: px2rem(254px);
                height: px2rem(26px);
                .h5Swiper-slide {
                  width: px2rem(254px) !important;
                  height: px2rem(26px) !important;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  .beele {
                      font-size: px2rem(12px);
                  }
                  .baby {
                    height: px2rem(26px);
                    padding-left: px2rem(3px);
                    width: 20px;
                    display: flex;
                    align-items: center;
                  }
                  .successful {
                    padding-left: px2rem(16px);
                    font-size: px2rem(12px);
                    span {
                        color: #FDCC05;
                    }
                  }
                  .prize {
                    // padding-left: px2rem(10px);
                    display: flex;
                    align-items: center;
                    img {
                      width: px2rem(10px);
                      height: px2rem(10px);
                      margin: 0 px2rem(2px) 0 px2rem(2px);
                    }
                    .ellipsis {
                      float: right;
                      margin: px2rem(-4px) 0 0 0;
                    }
                  }
                }
              }
        }
        .xh15 {
            position: absolute;
            width: px2rem(35px);
            height: px2rem(35px);
            top: 0px;
            right: px2rem(100px);
        }
        padding-top: px2rem(110px);
        .center {
            background-image: url("../../../assets/images/home/fire.gif");
            margin: auto;

            background-size: 100%;
            width: px2rem(226px);
            height: px2rem(175px);

            background-repeat: round;
            background-size: 100%;
            border-radius: px2rem(8px);
            position: relative;

            .deng {
                position: absolute;
                top: px2rem(-30px);
                left: px2rem(-30px);
                width: px2rem(286px);
                height: px2rem(235px);
            }

            .cards {
                // height: px2rem(140px);
                padding-top: px2rem(8px);
                display: flex;
                align-items: center;
                justify-content: space-around;
                .card {
                    height: px2rem(140px);
                    width: px2rem(97px);
                    background-repeat: no-repeat;
                    background-size: 100%;
                    padding-top: px2rem(9px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .cardImg {
                        width: px2rem(80px);
                        height: px2rem(80px);
                    }
                    .blance {
                        margin-top: px2rem(12px);
                        font-family: "SFCompactDisplayrightLight";
                        font-style: normal;
                        font-weight: 700;
                        font-size: px2rem(12px);
                        line-height: px2rem(12px);
                        text-align: center;

                        color: #ffffff;
                        label {
                            color: #fece1e;
                        }
                    }
                    .text {
                        text-align: center;
                        // font-family: "SFCompactDisplayrightLight";
                        font-style: normal;
                        font-weight: 400;
                        font-size: px2rem(12px);
                        line-height: px2rem(12px);

                        /* identical to box height, or 83% */

                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .logo {
                            width: px2rem(8px);
                            height: px2rem(8px);
                        }
                    }
                   
                }
                .card1 {
                    background-image: url("../../../assets/images/h5/GHC1_L_card.svg");
                }
                .card2 {
                    background-image: url("../../../assets/images/h5/card_hippo.svg");
                }
            }
            .btm {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: px2rem(8px);
                padding:0px  px2rem(8px);
                position: relative;
                > div {
                    font-family: "SFCompactDisplayrightLight";
                    font-style: normal;
                    font-weight: 600;
                    font-size: px2rem(12px);
                    line-height: px2rem(12px);
                    /* identical to box height, or 100% */

                    color: #fff9e8;
                }
            }
        }

        .btns {
            position: relative;
            margin-top: px2rem(26px);
            text-align: center;
            .mintBtn {
                width: px2rem(212px);
                height: px2rem(48px);
            }
            .mintText {
                margin-top: px2rem(5px);
                font-style: normal;
                font-weight: 600;
                font-size: px2rem(12px);
                text-transform: uppercase;
                color: #ffffff;
                label {
                    font-size: px2rem(18px);
                }
            }
            .historyBtn {
                position: absolute;
                width: px2rem(45px);
                height: px2rem(40px);
                right: px2rem(28px);
                top: px2rem(-5px);
            }
        }
    }
}
.white {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.95;
    z-index: 2;
    .loadWhite {
      height: px2rem(50px);
      width: px2rem(50px);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50%;
      margin-top: px2rem(-80px);
    }
    .propgr {
      height: 100%;
      font-size: px2rem(14px);
      text-align: center;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
}
.white2 {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.95;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .kpLoad {
      width: px2rem(294px);
      height: px2rem(294px);
    }
}
.mask {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #000000;
    opacity: 0.9;
    z-index: 999;
    .maskContent {
        width: 100%;
        color: #FFFFFF;
        position: absolute;
        top: calc(50% - px2rem(150px));
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-size: px2rem(16px);
        .btn {
            display: flex;
            justify-content: center;
            div {
                width: px2rem(59px);
                height: px2rem(59px);
                border-radius: 50%;
                background: #3d6bf5;
                margin-top: px2rem(24px);
                .img4 {
                    width: px2rem(38px);
                    height: px2rem(36px);
                    margin-top: px2rem(10px);
                }
                .img3 {
                   width: px2rem(33px);
                   height: px2rem(30px);
                   margin-top: px2rem(12px);
                }
            }
            .one {
                margin-right: px2rem(40px);
            }
        }
        .back {
            width: px2rem(146px);
            height: px2rem(50px);
            border-radius: 999rem;
            border: 1px solid #FECE1E;
            margin: 0 auto;
            line-height: px2rem(46px);
            color: #FECE1E;
            font-size: px2rem(20px);
            margin-top: px2rem(90px);
        }
    }
}
