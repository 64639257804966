@import '../../px2rem.scss';

.whiteContainer {
  height: px2rem(420px);
  .closeIcon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: px2rem(440px);
  }
  .topReamrk {
    text-align: center;
    font-size: px2rem(12px);
    color: #FECE1E;
    font-style: normal;
    font-weight: 400;
    padding-bottom: px2rem(20px);
  }
  .card {
    width: px2rem(234px);
    height: px2rem(261px);
    background: linear-gradient(180deg, rgba(253, 204, 5, 0.1) 0%, rgba(253, 204, 5, 0.05) 100%);
    border-radius: 10px;
    margin: 0 auto;
    .cardInner {
      width: px2rem(214px);
      height: px2rem(237px);
      background: url('../../assets/images/home/white-card.svg') no-repeat;
      background-size: px2rem(214px) px2rem(237px);
      margin: 0 auto;
      transform: translateY(px2rem(7px));
      .title {
        text-align: center;
        background: linear-gradient(270deg, #FDC805 5%, #FFFEF9 52.75%, #FDC805 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-family: 'SFCompactDisplay';
        font-style: normal;
        font-weight: 600;
        font-size: px2rem(30px);
        height: px2rem(76px);
        line-height: px2rem(76px);
        margin: 0 px2rem(15px);
        border-bottom: 1px dashed #FECE1E;
      }
      .remark {
        color: #FFF9E8;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        padding: px2rem(10px) px2rem(10px) 0;
        .label{
          span {
            text-decoration: line-through;
          }
        }
      }
    }
    .bottom {
      width: px2rem(234px);
      height: px2rem(62px);
      margin-top: px2rem(-20px);
      position: relative;
      z-index: 2;
    }
    .btn {
      width: px2rem(150px);
      height: px2rem(50px);
      background: linear-gradient(269.93deg, #FDCC05 0.93%, #FEEF67 127.01%);
      box-shadow: 0px px2rem(4px) px2rem(20px) rgba(254, 206, 9, 0.3);
      border-radius: px2rem(40px);
      margin: 0 auto;
      text-align: center;
      line-height: px2rem(50px);
      color: #623B00;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 600;
      font-size: px2rem(24px);
      margin-top: px2rem(18px);
    }
  }
}