.addFunddsDialog {
  .ant-modal {
    width: 464px;
    height: 333px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FECE1E;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
  }
  .ant-modal-content {
    width: 100%;
    height: 333px;
    background: none;
  }
  .ant-modal-body {
    width: 100%;
    height: 333px;
    background: none;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #FECE1E;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .dialogContent {
    width: 100%;
    height: 300px;
  }
  .dialogHeader {
    font-family: 'SFCompactDisplay';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    color: #FFF9E8;
    text-align: center;
    padding: 0 0 24px 0;
  }
  .dialogLogoStyle {
    height: 100px;
    width: 100px;
    margin-left: 180px;
  }
  .dialogRemark {
    font-family: 'SFCompactDisplayLight';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #FFF9E8;
    a {
      color: #FECE1E;
    }
  }
  .dialogRemark2 {
    font-family: 'SFCompactDisplayLight';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #FFF9E8;
    padding-bottom: 28px;
  }
  .dialogBottom {
    display: flex;
    justify-content: space-between;
  }
  .invitationCode {
    width: 350px;
    height: 50px;
    background: rgba(18, 14, 29, 0.3);
    border-radius: 10px;
    line-height: 50px;
    text-align: center;
    font-family: 'SFCompactDisplayLight';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #B7B5A5;
  }
  .copy {
    width: 89px;
    height: 50px;
    background: linear-gradient(269.93deg, #FDCC05 0.93%, #FEEF67 127.01%);
    box-shadow: 0px 4px 20px rgba(254, 206, 9, 0.3);
    border-radius: 10px;
    margin-left: 20px;
    border: none;
    font-family: 'SFCompactDisplay';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #623B00;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }
}