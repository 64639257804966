@import "../../px2rem.scss";

.h5NTFItemContainer {
    width: px2rem(140px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: px2rem(187px);
    margin: auto;
    .ntmImg {
        width: px2rem(130px);
        height: px2rem(130px);
        background-image: url(../../assets//images/home/GHC1_S_card.png);
        background-repeat: repeat;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .toubiao {
            position: absolute;
            width: px2rem(18px);
            height: px2rem(22px);
            top: 0px;
        }

        img {
            width: px2rem(86px);
            height: px2rem(86px);
            border-radius: px2rem(6px);
        }
    }
    .title {
        align-items: center;
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(14px);
        text-transform: uppercase;
        color: #fff9e8;
        margin-bottom: px2rem(8px);
    }
    .price {
        width: px2rem(110px);
        height: px2rem(38px);
        background: rgba(253, 204, 5, 0.2);
        border: 1px dashed #fece1e;
        border-radius: px2rem(6px);
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(12px);
        line-height: px2rem(14px);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fdcc05;
    }
}
.h5NTFMHItemContainer {
    .ntmImg {
        background-image: url(../../assets//images/home/maghe_kuang.png);
    }

    .price {
        width: px2rem(110px);
        height: px2rem(38px);
        background: transparent;
        border: 1px dashed #fff9e8;
        color: #fff9e8;
    }
}
