.h5Title {
    font-family: "SFCompactDisplayBlod";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #fff9e8;
}
.h5NavBar {
    background-color: #37364d;
    color: #fff;
}
