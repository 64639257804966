@import "../../px2rem.scss";

.cardcontentContainer {
    width: 100%;
    height: px2rem(285px);
    position: relative;

    .topRoll {
        width: px2rem(236px);
        height: px2rem(17px);
        background: rgba(20, 16, 30, 0.3);
        border-radius: px2rem(23px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: px2rem(-28px);
        overflow: hidden;
        color: #fff9e8;
        font-size: px2rem(10px);
        font-weight: 400;
        font-family: "SFCompactDisplayLight";
        z-index: 1;
        .h5Swiper-container {
            width: px2rem(236px);
            height: px2rem(17px);
            .h5Swiper-slide {
                width: px2rem(236px) !important;
                height: px2rem(17px) !important;
                display: flex;
                justify-content: space-around;
                align-items: center;
                .beele {
                    font-size: px2rem(12px);
                }
                .baby {
                    height: px2rem(17px);
                    padding-left: px2rem(3px);
                    width: 20px;
                    display: flex;
                    align-items: center;
                }
                .successful {
                    padding-left: px2rem(10px);
                    display: flex;
                    align-items: center;
                    span {
                        color: #fdcc05;
                    }
                }
                .prize {
                    // padding-left: px2rem(10px);
                    display: flex;
                    align-items: center;
                    img {
                        width: px2rem(10px);
                        height: px2rem(10px);
                        margin: 0 px2rem(2px) 0 px2rem(2px);
                    }
                    .ellipsis {
                        float: right;
                        margin: px2rem(-4px) 0 0 0;
                    }
                }
            }
        }
    }

    .card-body {
        position: absolute;
        width: px2rem(335px);
        height: px2rem(328px);
        background-image: url("../../assets/images/h5/kuang2.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: auto;
        top: px2rem(-43px);
        left: 50%;
        transform: translateX(-50%);

        .deng {
            position: absolute;
            top: px2rem(25px);
            left: px2rem(-8px);
            width: px2rem(352px);
            height: px2rem(285px);
        }
        padding-top: px2rem(56px);
        padding-left: px2rem(24px);
        .content {
            width: px2rem(289px);
            height: px2rem(225px);

            .cards {
                width: px2rem(289px);
                height: px2rem(180px);
                display: flex;
                justify-content: space-around;
                margin-top: px2rem(10px);
                .card {
                    width: px2rem(124px);
                    height: px2rem(180px);
                    background-repeat: no-repeat;
                    // background-position: px2rem(-20px) px2rem(-20px);
                    padding: px2rem(11px);
                    background-size: 100% 100%;

                    .cardImg {
                        width: px2rem(100px);
                        height: px2rem(100px);
                        border-radius: px2rem(10px);
                    }
                    .blance {
                        margin-top: px2rem(15px);
                        font-family: "SFCompactDisplayrightLight";
                        font-style: normal;
                        font-weight: 700;
                        font-size: px2rem(12px);
                        line-height: px2rem(12px);
                        text-align: center;

                        color: #ffffff;
                        label {
                            color: #fece1e;
                        }
                    }
                    .text {
                        // font-family: "SFCompactDisplayrightLight";
                        font-style: normal;
                        font-weight: 400;
                        font-size: px2rem(12px);
                        line-height: px2rem(12px);

                        /* identical to box height, or 83% */
                        margin-top: px2rem(2px);
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .logo {
                            width: px2rem(10px);
                            height: px2rem(10px);
                            margin-right: px2rem(3px);
                        }
                    }
                }
                .card1 {
                    background-image: url("../../assets/images/h5/dash-kuang0.svg");
                }
                .card2 {
                    background-image: url("../../assets/images/h5/dash-kuang1.svg");
                }
            }
            .btm {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: px2rem(10px);
                padding: 0px px2rem(10px);
                position: relative;
                > div {
                    font-family: "SFCompactDisplayrightLight";
                    font-style: normal;
                    font-weight: 600;
                    font-size: px2rem(12px);
                    line-height: px2rem(12px);
                    /* identical to box height, or 100% */

                    color: #fff9e8;
                }
            }
        }
        .card-footer {
            margin-top: px2rem(6px);
            height: px2rem(28px);
            font-family: "SFCompactDisplayrightLight";
            font-style: normal;
            font-weight: 700;
            font-size: px2rem(12px);
            line-height: px2rem(28px);
            text-transform: uppercase;
            color: #ffffff;
            text-align: center;
            label {
                font-size: px2rem(18px);
            }
        }
    }
}
