@import '../../px2rem.scss';

.h5Loading {
  .topGif {
    width: px2rem(222px);
    height: px2rem(80px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: px2rem(220px);
  }
  .card {
    width: px2rem(254px);
    height: px2rem(200px);
    background: rgb(132, 87, 58);
    border-radius: px2rem(8px);
    
    .deng1 {
      width: px2rem(284px);
      height: px2rem(220px);
      background: url('../../assets/images/home/deng1.svg') no-repeat;
      background-size: cover;
      margin-left: px2rem(-16px);
      transform: translateY(px2rem(-16px));
      display: flex;
    }
    .deng2 {
      width: px2rem(284px);
      height: px2rem(220px);
      background: url('../../assets/images/home/deng2.svg') no-repeat;
      background-size: cover;
      margin-left: px2rem(-16px);
      transform: translateY(px2rem(-16px));
      display: flex;
    }
    .fire {
      width: px2rem(226px);
      height: px2rem(175px);
      background: url('../../assets/images/home/fire.gif') no-repeat;
      background-size: px2rem(226px) px2rem(175px);
      border-radius: px2rem(8px);
      transform: translateY(px2rem(-208px));
      margin: 0 auto;
    }
    .inner {
      width: px2rem(226px);
      height: px2rem(175px);
      transform: translateY(px2rem(-383px));
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .uncommon {
        width: px2rem(97px);
        height: px2rem(140px);
        background: rgba(167, 124, 41, 0);
        box-shadow: 0px 0px 20px rgba(253, 204, 5, 0.1), inset 0px -4px 20px rgba(254, 203, 70, 0.2), inset 0px 4px 20px rgba(212, 154, 5, 0.2);
        backdrop-filter: blur(10px);
        border-radius: px2rem(8px);
        margin-top: px2rem(10px);
        .hm {
          width: px2rem(97px);
          height: px2rem(140px);
          background: url('../../assets/images/h5/GHC1_L_card.svg') no-repeat;
          background-size: px2rem(97px) px2rem(140px);
        }
        .mh {
          width: px2rem(97px);
          height: px2rem(140px);
          background: url('../../assets/images/h5/card_hippo.svg') no-repeat;
          background-size: px2rem(97px) px2rem(140px);
        }
        img {
          width: px2rem(77px);
          height: px2rem(77px);
          margin: px2rem(10px) 0 0 px2rem(10px);
          border-radius: px2rem(8px);
        }
        .price {
          text-align: center;
          padding-top: px2rem(6px);
          // font-family: 'SFCompactDisplay';
          font-style: normal;
          font-weight: 700;
          font-size: px2rem(12px);
          span {
            color: #FECE1E;
          }
        }
        .bottom {
          display: flex;
          justify-content: center;
          img {
            height: px2rem(12px);
            width: px2rem(12px);
            margin: 0;
            text-align: center;
          }
          span {
            font-size: px2rem(12px);
            margin-top: px2rem(-4px);
          }
        }
      }
      .active {
        width: px2rem(97px);
        height: px2rem(140px);
        background: rgba(167, 124, 41, 1);
        box-shadow: 0px 0px 20px rgba(253, 204, 5, 0.1), inset 0px -4px 20px rgba(254, 203, 70, 0.2), inset 0px 4px 20px rgba(212, 154, 5, 0.2);
        backdrop-filter: blur(10px);
        border-radius: px2rem(8px);
        margin-top: px2rem(10px);
        .hm {
          width: px2rem(97px);
          height: px2rem(140px);
          background: url('../../assets/images/h5/GHC1_L_card.svg') no-repeat;
          background-size: px2rem(97px) px2rem(140px);
        }
        .mh {
          width: px2rem(97px);
          height: px2rem(140px);
          background: url('../../assets/images/h5/card_hippo.svg') no-repeat;
          background-size: px2rem(97px) px2rem(140px);
        }
        img {
          width: px2rem(77px);
          height: px2rem(77px);
          margin: px2rem(10px) 0 0 px2rem(10px);
          border-radius: px2rem(8px);
        }
        .price {
          text-align: center;
          padding-top: px2rem(6px);
          // font-family: 'SFCompactDisplay';
          font-style: normal;
          font-weight: 700;
          font-size: px2rem(12px);
          span {
            color: #FECE1E;
          }
        }
        .bottom {
          display: flex;
          justify-content: center;
          img {
            height: px2rem(12px);
            width: px2rem(12px);
            margin: 0;
            text-align: center;
          }
          span {
            font-size: px2rem(12px);
            margin-top: px2rem(-4px);
          }
        }
      }
    }
  }
}