@import '../../px2rem.scss';

.cardContents {
  height: px2rem(415px);
  width: px2rem(335px);
  background: url('../../assets/images/h5/kuang@2x.png') no-repeat;
  background-size: px2rem(335px) px2rem(415px);
  margin: 0 auto;
  margin-top: px2rem(-43px);
  position: relative;
  .topRoll {
    width: px2rem(236px);
    height: px2rem(17px);
    background: rgba(20, 16, 30, 0.3);
    border-radius: px2rem(23px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: px2rem(17px);
    overflow: hidden;
    color: #FFF9E8;
    font-size: px2rem(10px);
    font-weight: 400;
    font-family: 'SFCompactDisplayLight';
    .h5Swiper-container {
      width: px2rem(236px);
      height: px2rem(17px);
      .h5Swiper-slide {
        width: px2rem(236px) !important;
        height: px2rem(17px) !important;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .beele {
          font-size: px2rem(12px);
        }
        .baby {
          height: px2rem(17px);
          padding-left: px2rem(3px);
          width: 20px;
          display: flex;
          align-items: center;
        }
        .successful {
          padding-left: px2rem(10px);
          display: flex;
          align-items: center;
          span {
            color: #FDCC05;
          }
        }
        .prize {
          // padding-left: px2rem(10px);
          display: flex;
          align-items: center;
          img {
            width: px2rem(10px);
            height: px2rem(10px);
            margin: 0 px2rem(2px) 0 px2rem(2px);
          }
          .ellipsis {
            float: right;
            margin: px2rem(-4px) 0 0 0;
          }
        }
      }
    }
  }
  .card {
    height: px2rem(293px);
    width: px2rem(310px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: px2rem(45px);
    .inner {
      height: px2rem(262px);
      width: px2rem(310px);
      display: flex;
      margin-top: px2rem(20px);
      justify-content: space-around;
      flex-wrap: wrap;
      .simpleCard {
        width: px2rem(92px);
        height: px2rem(121px);
        border-radius: px2rem(10px);
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0px 0px 20px rgba(253, 204, 5, 0.1), inset 0px -4px 20px rgba(254, 203, 70, 0.2), inset 0px 4px 20px rgba(212, 154, 5, 0.2);
        backdrop-filter: blur(10px);
        border-radius: px2rem(6px);
        position: relative;
      }
      .cardLight {
        width: px2rem(92px);
        height: px2rem(121px);
        background: rgba(253, 204, 5, 0.3);
        backdrop-filter: blur(10px);
        border-radius: px2rem(6px);
        position: relative;
      }
      .cardImgs {
        img {
          height: px2rem(160px);
          width: px2rem(130px);
          margin: px2rem(-20px) 0 0 px2rem(-19px);
        }
        
      }
      .cardImg {
        height: px2rem(160px);
        width: px2rem(130px);
        margin: px2rem(-20px) 0 0 px2rem(-19px);
      }
      .hippoCard1 {
        width: px2rem(70px);
        height: px2rem(70px);
        position: absolute;
        border-radius: px2rem(4px);
        top: px2rem(6px);
        left: px2rem(10px);
        border: 1px solid #DEB201;
        border-radius: 8px;
      }
      .hippoCard {
        width: px2rem(70px);
        height: px2rem(70px);
        position: absolute;
        border-radius: px2rem(4px);
        top: px2rem(6px);
        left: px2rem(10px);
      }
      .number {
        position: absolute;
        color: #FECE1E;
        bottom: px2rem(24px);
        left: 50%;
        font-size: px2rem(10px);
        font-style: normal;
        font-weight: 700;
        width: 100%;
        text-align: center;
        font-family: 'SFCompactDisplayLight';
        transform: translateX(-50%);
        .sol {
          color: white;
        }
      }
      .value {
        position: absolute;
        bottom: px2rem(10px);
        left: 50%;
        transform: translateX(-50%);
        color: #FFFFFF;
        font-family: 'SFCompactDisplayLight';
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(9px);
        display: flex;
        justify-content: center;
        width: 100%;
        .meFive {
          width: px2rem(12px);
          height: px2rem(12px);
          margin: px2rem(2px) px2rem(2px) 0 0;
        }
        .me {
          width: px2rem(12px);
          height: px2rem(12px);
          margin: px2rem(2px) px2rem(2px) 0 0;
        }
      }
      .bottom1 {
        position: absolute;
        bottom: px2rem(-4px);
        left: 50%;
        transform: translateX(-50%);
        color: #623B00;
        font-family: 'SFCompactDisplayLight';
        font-style: normal;
        font-weight: 600;
        font-size: px2rem(9px);
        text-transform: uppercase;
      }
    }
    .second {
      position: absolute;
      top: px2rem(-20px);
      width: 100%;
      // left: 50%;
      // transform: translateX(-50%);
      color: rgba(255, 249, 232, 0.6);
      font-size: px2rem(12px);
      font-family: 'SFCompactDisplayLight';
      .middle {
        width: 100%;
        text-align: center;
      }
      i {
        width: px2rem(6px);
        height: px2rem(6px);
        background: rgba(255, 241, 204, 0.6);
        border-radius: 50%;
        margin: px2rem(5px) px2rem(2px) 0 0;
      }
    }
    .cardBottom {
      width: 100%;
      height: px2rem(16px);
      line-height: px2rem(16px);
      position: absolute;
      bottom: px2rem(0px);
      color: rgba(255, 249, 232, 0.6);
      font-size: px2rem(12px);
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      padding-left: px2rem(2px);
      .total {
        text-align: center;
      }
      .refresh {
        margin-left: px2rem(2px);
      }
    }
  }
  .slider {
    width: px2rem(270px);
    height: px2rem(35px);
    position: absolute;
    bottom: px2rem(10px);
    left: 50%;
    transform: translateX(-50%);
    .adm-slider {
      width: px2rem(270px);
      height: px2rem(35px);
    }
    .adm-slider-fill {
      height: px2rem(8px);
      background: linear-gradient(140.3deg, #FFF1C1 2.92%, #FFEEA8 46.48%);
      box-shadow: 0px 0px 20px rgba(253, 204, 5, 0.3);
    }
    .adm-slider-mark {
      margin-top: px2rem(-40px);
      .adm-slider-mark-text {
        color: #FFF9E8;
        font-size: px2rem(14px);
        font-family: 'SFCompactDisplayLight';
        font-style: normal;
      }
    }
    .adm-slider-track {
      height: px2rem(8px);
    }
    .adm-slider-tick {
      height: px2rem(10px);
      width: px2rem(2px);
      top: px2rem(-9px);
      background: #FFF1CC;
      border-radius: px2rem(8px);
    }
    .adm-slider-mark-text {
      margin-left: px2rem(-2px);
    }
    .adm-slider-thumb-container {
      height: px2rem(24px);
      width: px2rem(24px);
    }
    .adm-slider-thumb {
      height: px2rem(24px);
      width: px2rem(24px);
      background: none;
      background-image: none;
      background: url('../../assets/images/home/slider.png') no-repeat;
      background-size: px2rem(24px) px2rem(24px);
      border: none;
      color: none;
      box-shadow: none;
      margin-top: px2rem(2px);
      svg {
        display: none;
      }
    }
  }
}