.historyDialog {
  .ant-modal {
    width: 950px !important;
    height: 537px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FECE1E;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
  }
  .ant-modal-content {
    width: 950px;
    height: 537px;
    background: none;
  }
  .ant-modal-body {
    width: 950px;
    height: 537px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #FECE1E;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .dialogContent {
    height: 100%;
    width: 100%;
    .headerBg {
      height: 65px;
      width: 376px;
      margin: -50px 0 36px 265px;
      background: url('../../../assets/images/home/mint_BT.png') no-repeat;
      background-size: 376px 65px;
      float: left;
    }
    .dialogHeader {
      float: left;
      font-family: 'SFCompactDisplayBlod';
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      text-transform: uppercase;
      color: #623B00;
      z-index: 20;
      margin:-42px 0 0 -250px;
    }
    .tableHeader {
      width: 856px;
      height: 33px;
      line-height: 33px;
      background: rgba(255, 249, 232, 0.1);
      border-radius: 5px;
      margin: 0 0 0 20px;
      color: #FFF9E8;
      display: flex;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      .item {
        width: 276px;
        height: 33px;
        text-align: center;
      }
      .priceItem {
        width: 150px;
        height: 33px;
      }
      .fromItem {
        width: 150px;
        height: 33px;
      }
      .timeItem {
        width: 260px;
        text-align: center;
      }
    }
    .tableContent {
      height: 400px;
      width: 866px;
      margin-left: 40px;
      overflow-y: auto;
      .tableItem {
        height: 50px;
        width: 100%;
        margin-top: 30px;
        .shopping {
          width: 24px;
          height: 23px;
          margin-top: 12px;
          float: left;
        }
        .imgPic {
          height: 50px;
          width: 50px;
          margin-left: 47px;
          float: left;
        }
        .name {
          color: #FFF9E8;
          font-family: 'SFCompactDisplayLight';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          float: left;
          padding:4px 0 0 8px;
          .hisName {
            font-size: 16px;
          }
        }
        .price {
          color: #FFF9E8;
          font-family: 'SFCompactDisplayLight';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          float: left;
          padding:5px 0 0 80px;
          .bnb {
            font-size: 16px;
          }
        }
        .from {
          color: #FECE1E;
          font-family: 'SFCompactDisplayLight';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          padding: 18px 0 0 100px;
          float: left;
          cursor: pointer;
        }
        .to {
          color: #FECE1E;
          font-family: 'SFCompactDisplayLight';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          padding: 18px 0 0 100px;
          float: left;
          cursor: pointer;
        }
        .time {
          color: #FFF9E8;
          font-family: 'SFCompactDisplayLight';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          padding: 18px 0 0 50px;
          float: left;
        }
      }
    }
    ::-webkit-scrollbar {
      background: 0;
      height: 20px;
      width: 4px;
    }
    ::-webkit-scrollbar-thumb:vertical {
      background: rgba(255, 249, 232, 0.3);
      border-radius: 8px;
    }
  }
}