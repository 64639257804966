@import "../../../px2rem.scss";
.add_purchaseContainer {
    padding: 20px;
    .title {
        margin-top: px2rem(30px);
        font-family: "SFCompactDisplayBlod";
        font-style: normal;
        font-weight: 600;
        font-size: px2rem(16px);
        text-align: center;

        color: #fff9e8;
        text-align: center;
        label {
            color: #fece1e;
        }
    }

    .describe {
        margin-top: px2rem(8px);
        font-family: "SFCompactDisplayBlod";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(14px);
        text-align: left;

        color: #fff9e8;
    }

    .balance {
        margin-top: px2rem(66px);
        margin-bottom: px2rem(8px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        > label:first-child {
            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 600;
            font-size: px2rem(14px);
            text-align: center;

            color: #fff9e8;
        }
        > label:last-child {
            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 400;
            font-size: px2rem(12px);

            text-align: center;

            color: #fff9e8;
        }
    }

    .wallet {
        height: px2rem(50px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: px2rem(15px);
        background: rgba(18, 14, 29, 0.3);
        border-radius: px2rem(6px);
        font-size: px2rem(12px);
        img{
            width: px2rem(20px);
            height: px2rem(20px);
        }
    }

    .continuePanel {
        margin-top: px2rem(24px);
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
            width: px2rem(218px);
            height: px2rem(50px);
            display: flex;
            justify-content: center;
            align-items: center;

            background: #fdcc05;
            box-shadow: 0px 4px 20px rgba(254, 206, 9, 0.3);
            border-radius: px2rem(40px);

            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 600;
            font-size: px2rem(20px);

            color: #623b00;
        }
    }
    .add_funds {
        margin-top: px2rem(24px);
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "SFCompactDisplayBlod";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(18px);
        text-align: center;

        color: #fece1e;
    }
}
