@import "../../px2rem.scss";
.h5CongratulationsFooter {
    height: px2rem(100px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border-top: 1px solid #adadad;
    box-shadow: 0px 20px 4px rgba(0, 0, 0, 0.5), inset 0px -4px 60px rgba(166, 139, 197, 0.4),
        inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);

    padding: px2rem(8px) px2rem(20px);

    .text {
        font-family: "SF Compact Display";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(12px);
        text-align: center;
        color: #fece1e;
        label {
            font-weight: 700;
            color: #ffffff;
        }
    }
    .btns {
        display: flex;
        justify-content: space-between;
        margin-top:px2rem(12px);
        >div {
            width: px2rem(162px);
            height: px2rem(46px);
            border-radius: px2rem(6px);
            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 600;
            font-size: px2rem(14px);
            line-height: px2rem(16px);
            /* identical to box height, or 114% */ 
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width:px2rem(22px);
                height: px2rem(22px);
                margin-right: px2rem(5px);
            }
        }
        >div.btn1 {
            background: #fdcc05;
            box-shadow: 0px 4px 30px rgba(245, 181, 46, 0.2), inset 1px 1px 2px rgba(255, 247, 213, 0.3);
            color: #623b00;
        }
        >div.btn2 {
            border: 1px solid #fece1e;
            box-shadow: inset -2px -1px 1px rgba(255, 243, 221, 0.6), inset 1px 1px 2px rgba(255, 247, 213, 0.3);
            filter: drop-shadow(0px 4px 30px rgba(245, 181, 46, 0.2)); 
            color: #fece1e;
        }

        >div.disabled{
            opacity: 0.3;
        }
    }
}
