@import '../../../px2rem.scss';

.trade {
  height: 100%;
  background-color: #37364d;
  .tradeContainer {
    height: calc(100% - px2rem(43px));
    position: relative;
    .content {
      height: px2rem(160px);
      width: 100%;
      position: absolute;
      top: calc(50% - px2rem(50px));
      transform: translateY(-50%);
      .box {
        width: 100%;
        height: px2rem(80px);
        width: px2rem(250px);
        display: block;
        margin: 0 auto;
      }
      .remark {
        width: 100%;
        text-align: center;
        font-family: SFCompactDisplayLight;
        font-size: px2rem(14px);
        font-style: normal;
        font-weight: 400;
        color: #fff;
        span {
          color: #FDCC05;
        }
      }
    }
  }
}