@import "../../px2rem.scss";

.historyPanel {
    height: px2rem(143px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 11.13%, rgba(255, 255, 255, 0) 100%);
    transform: translateY( px2rem(-6px));
    position: relative;
    .top{
        position: absolute;
        width: px2rem(15px);
        height:  px2rem(15px);
        top:px2rem(12px);
    }
    .top1{
        left:px2rem(12px);
    }
    .top2{
        right:px2rem(12px);
    }
    .histroyLefticon{
        position: absolute;
        width: px2rem(46px);
        height:  px2rem(66px);
        top:px2rem(28px);
        left:px2rem(51px);
    }
    .histroyRighticon{
        position: absolute;
        width: px2rem(68px);
        height:  px2rem(55px);
        top:px2rem(28px);
        right:px2rem(45px);
    }
    .historyCenterIcon{
        position: absolute;
        width: px2rem(97px);
        height:  px2rem(36px);
        top:px2rem(28px);
        left:50%;
        transform: translateX(-50%);
    }
}
