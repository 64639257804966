@import '../../../px2rem.scss';

.balanceContainer {
  height: 100%;
  position: relative;
  background: rgb(55, 54, 77);
  .topCard {
    width: 100%;
    height: px2rem(78px);
    background-image: url("../../../assets/images/h5/group.svg");
    background-repeat: no-repeat;
    background-size: px2rem(375px) px2rem(78px);
    position: relative;
    .topPanel{
      height: px2rem(78px);
      padding: px2rem(13px)  px2rem(18px);
    }
  }
  .headImg {
    height: px2rem(60px);
    width: px2rem(60px);
    margin: 0 auto;
    z-index: 4;
    margin-top: px2rem(-40px);
  }
  .logo {
    width: px2rem(138px);
    height: px2rem(20px);
    margin: px2rem(8px) auto;
  }
  .wallet {
    color: #FFF9E8;
    font-family: 'SFCompactDisplayLight';
    font-style: normal;
    font-weight: 400;
    font-size: px2rem(16px);
    text-align: center;
    margin-bottom: px2rem(15px);
  }
  .moneyBox {
    width: px2rem(334px);
    height: px2rem(152px);
    background: #292839;
    border-radius: px2rem(10px);
    margin: 0 auto;
    text-align: center;
    margin-bottom: px2rem(55px);
    .money {
      font-family: 'SFCompactDisplay';
      font-style: normal;
      font-weight: 700;
      font-size: px2rem(40px);
      color: #FFF9E8;
      line-height: px2rem(152px);
    }
    .dw {
      color: #FFF9E8;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: px2rem(14px);
      transform: translateY(px2rem(-50px));
    }
  }
  .addBtn {
    width: px2rem(260px);
    height: px2rem(50px);
    background: #FDCC05;
    box-shadow: 0px px2rem(4px) px2rem(30px) rgba(245, 181, 46, 0.2), inset px2rem(1px) px2rem(1px) px2rem(2px) rgba(255, 247, 213, 0.3);
    border-radius: px2rem(6px);
    margin: 0 auto;
    line-height: px2rem(50px);
    text-align: center;
    color: #623B00;
    font-family: 'SFCompactDisplayBlod';
    font-style: normal;
    font-weight: 700;
    font-size: px2rem(20px);
    margin-bottom: px2rem(28px);
  }
  .refBtn {
    width: px2rem(260px);
    height: px2rem(50px);
    border: px2rem(1px) solid #FECE1E;
    box-shadow: inset px2rem(-2px) px2rem(-1px) px2rem(1px) rgba(255, 243, 221, 0.6), inset px2rem(1px) px2rem(1px) px2rem(2px)rgba(255, 247, 213, 0.3);
    filter: drop-shadow(0px px2rem(4px) px2rem(30px) rgba(245, 181, 46, 0.2));
    border-radius: px2rem(6px);
    text-align: center;
    margin: 0 auto;
    line-height: px2rem(50px);
    color: #FECE1E;
    font-family: 'SFCompactDisplay';
    font-style: normal;
    font-weight: 600;
    font-size: px2rem(20px);
  }
  .out {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: px2rem(145px);
    .ell {
      width: px2rem(17px);
      height: px2rem(18px);
    }
    span {
      color: #FECE1E;
      font-family: 'SF Compact Display';
      font-style: normal;
      font-weight: 400;
      font-size: px2rem(16px);
      transform: translateY(px2rem(-6px));
      padding-left: px2rem(10px);
    }
  }
}
