@import '../../px2rem.scss'; 

.partner {
  height: px2rem(220px);
  width: 100%;
  margin: 0 0 36px 0;
  .roadAsid {
    height: px2rem(23px);
    width: 100%;
    margin-bottom: px2rem(16px);
    .roadList {
      height: px2rem(23px);
      width: 100%;
      .roadmap {
        height: px2rem(23px);
        width: 100%;
        text-align: center;
        line-height: px2rem(23px);
        display: flex;
        justify-content: space-between;
        .road {
          font-family: 'WhiteNTBold';
          font-style: normal;
          font-weight: 700;
          font-size: px2rem(18px);
          text-transform: uppercase;
          background: linear-gradient(270deg, #FDCC05 1.52%, #FED462 87.8%);
          -webkit-text-fill-color: transparent;
          background-clip: text;
          height: px2rem(23px);
          width: 700px;
          float: left;
        }
        .partnerLeft {
          height: px2rem(8px);
          width: px2rem(74px);
          float: left;
          margin-top: px2rem(6px);
        }
        .partnerRight {
          height: px2rem(8px);
          width: px2rem(74px);
          float: left;
          margin: px2rem(6px) 0 0 px2rem(-4px);
        }
      }
    }
  }
  .partnerContent {
    height: px2rem(180px);
    width: px2rem(335px);
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px -0.5px 5px rgba(166, 139, 197, 0.4), inset 0px 1px 5px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(px2rem(10px));
    border-radius: px2rem(10px);
    .sponsorOne {
      height: px2rem(30px);
      width: 100%;
      display:grid;
      justify-items: center;
      align-items: center;
      grid-template: repeat(1,px2rem(30px)) / repeat(5,px2rem(67px));
      .sponsorImg {
        width: 100%;
        height: 100%;
        display:grid;
        justify-items: center;
        align-items: center;
        border-bottom: 0.5px solid rgb(94, 80, 46);
        border-right: 0.5px solid rgb(94, 80, 46);
      }
      .sponsor1 {
        width: 100%;
        height: 100%;
        display:grid;
        justify-items: center;
        align-items: center;
        border-right: none;
        border-bottom: 0.5px solid rgb(94, 80, 46);
      }
      div {
        .imgOne {
          width: px2rem(39px);
          height: px2rem(10px);
        }
        .imgTwo {
          width: px2rem(51px);
          height: px2rem(9px);
        }
        .imgThree {
          width: px2rem(54px);
          height: px2rem(8px);
        }
        .imgFour {
          width: px2rem(33px);
          height: px2rem(17px);
        }
        .imgFive {
          width: px2rem(34px);
          height: px2rem(9px);
        }
        .imgSix {
          width: px2rem(39px);
          height: px2rem(10px);
        }
        .imgSeven {
          width: px2rem(48px);
          height: px2rem(12px);
        }
        .imgEight {
          width: px2rem(55px);
          height: px2rem(12px);
        }
        .imgNine {
          width: px2rem(45px);
          height: px2rem(10px);
        }
        .imgTen {
          width: px2rem(42px);
          height: px2rem(11px);
        }
        .imgEle {
          width: px2rem(38px);
          height: px2rem(12px);
        }
        .imgTwe {
          width: px2rem(50px);
          height: px2rem(13px);
        }
        .imgThi {
          width: px2rem(46px);
          height: px2rem(10px);
        }
        .imgFourteen {
          width: px2rem(51px);
          height: px2rem(11px);
        }
        .imgFiveteen {
          width: px2rem(37px);
          height: px2rem(12px);
        }
        .imgSixteen {
          width: px2rem(46px);
          height: px2rem(7px);
        }
        .imgSeventeen {
          width: px2rem(53px);
          height: px2rem(8px);
        }
        .imgEighteen {
          width: px2rem(56px);
          height: px2rem(14px);
        }
        .imgNines {
          width: px2rem(40px);
          height: px2rem(11px);
        }
        .imgTwenty {
          width: px2rem(30px);
          height: px2rem(6px);
        }
        .imgTwenOne {
          width: px2rem(54px);
          height: px2rem(9px);
        }
        .imgTwenTwo {
          width: px2rem(52px);
          height: px2rem(10px);
        }
        .imgTwenThree {
          width: px2rem(46px);
          height: px2rem(17px);
        }
        .imgTwenFour {
          width: px2rem(45px);
          height: px2rem(13px);
        }
        .imgTwenFive {
          width: px2rem(52px);
          height: px2rem(18px);
        }
      }
    }
    .sponsorTwo {
      height: px2rem(30px);
      width: 100%;
      display:grid;
      justify-items: center;
      align-items: center;
      grid-template: repeat(1,px2rem(30px)) / repeat(5,px2rem(67px));
      .sponsorImg {
        width: 100%;
        height: 100%;
        display:grid;
        justify-items: center;
        align-items: center;
        border-right: px2rem(0.5px) solid rgb(94, 80, 46);
      }
      .sponsor1 {
        width: 100%;
        height: 100%;
        display:grid;
        justify-items: center;
        align-items: center;
        border-right: none;
      }
      div {
        .imgTwo {
          width: px2rem(54px);
          height: px2rem(9px);
        }
        .imgThree {
          width: px2rem(26px);
          height: px2rem(19px);
        }
        .imgFour {
          width: px2rem(33px);
          height: px2rem(14px);
        }
        .imgFive {
          width: px2rem(50px);
          height: px2rem(7px);
        }
        .imgSix {
          width: px2rem(25px);
          height: px2rem(16px);
        }
      }
    }
  }
}