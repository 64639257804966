@import '../../../px2rem.scss';

.whiteList {
  .topCard {
    width: 100%;
    height: px2rem(92px);
    // background-image: url("../../../assets/images/h5/collection.png");
    background-repeat: repeat;
    background-size: 100%;
    position: relative;
    .topPanel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: px2rem(13px) px2rem(18px);
    }
  }
  .titleCard {
    position: relative;
    width: 100%;
    height: fit-content;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0) 81.9%);
    border-radius: px2rem(20px);
    border-top: 1px solid #fece1e;
    display: flex;
    justify-content: center;
    text-align: center;
    .text {
      font-family: "SFCompactDisplayBlod";
      font-style: normal;
      font-weight: 600;
      font-size: px2rem(14px);
      /* identical to box height, or 143% */
      text-align: center;
      color: #fece1e;
      padding-top: px2rem(24px);
    }
    .title {
      position: absolute;
      transform: translate(-50%, -55%);
      top: 0px;
      left: 50%;
      height: px2rem(38px);
      width: px2rem(276px);
      background-image: url("../../../assets/images/h5/concetionTitlebg.svg");
      background-repeat: repeat;
      background-size: 100%;

      font-family: "SFCompactDisplayBlod";
      font-style: normal;
      font-weight: 700;
      font-size: px2rem(20px);
      /* or 150% */
      line-height: px2rem(38px);
      text-transform: uppercase;

      color: #623b00;

      display: flex;
      justify-content: center;
    }
    .talk {
      color: #FECE1E;
      font-size: px2rem(14px);
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      padding-top: px2rem(40px);
    }
  }
  .list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: px2rem(30px);
    .inner {
      width: px2rem(334px);
      height: px2rem(160px);
      background: url('../../../assets/images/home/transverse.svg') no-repeat;
      background-size: px2rem(334px) px2rem(160px);
      margin-bottom: px2rem(28px);
      .title {
        width: px2rem(120px);
        height: px2rem(116px);
        line-height: px2rem(116px);
        text-align: center;
        border-right: 1px dashed #FECE1E;
        margin: px2rem(23px) 0;
        font-family: 'SFCompactDisplay';
        font-style: normal;
        font-weight: 700;
        background: linear-gradient(270deg, #FDC805 5%, #FFFEF9 52.75%, #FDC805 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        float: left;
      }
      .remarkList {
        width: px2rem(212px);
        height: px2rem(129px);
        float: left;
        padding: px2rem(18px) 0 0 px2rem(7px);
        .remark {
          font-style: normal;
          font-weight: 400;
          font-size: px2rem(10px);
          color: #FFF9E8;
          .label {
            span {
              text-decoration: line-through;
            }
          }
        }
      }
      
    }
  }
}