@import '../../px2rem.scss';

.statrMint {
  width: 100%;
  height: px2rem(165px);
  margin: 0 auto;
  background: url('../../assets/images/h5/rectangle.png') no-repeat;
  background-size: 100% px2rem(165px);
  position: relative;
  .mintTop {
    height: px2rem(50px);
    width: 100%;
    display: flex;
    justify-content: space-around;
    line-height: px2rem(50px);
    font-family: 'SFCompactDisplayLight';
    font-style: normal;
    font-weight: 900;
    font-size: px2rem(20px);
    text-transform: uppercase;
    color: #FFFFFF;
    span {
      font-size: px2rem(16px);
    }
    img {
      width: px2rem(122px);
      height: px2rem(41px);
      margin-top: px2rem(10px);
    }
  }
  .mintBtn {
    width: px2rem(258px);
    height: px2rem(74px);
    margin: 0 auto;
    margin-top: px2rem(10px);
    background: url('../../assets/images/home/btn@2x.png') no-repeat;
    background-size: px2rem(258px) px2rem(74px);
    transform: translateY(px2rem(-28px));
    z-index: 2;
  }
  .zhuangshi {
    width: px2rem(342px);
    height: px2rem(25px);
    margin: 0 auto;
    transform: translateY(px2rem(62px));
    z-index: 1;
    img {
      width: px2rem(342px);
      height: px2rem(25px);
      z-index: 1;
    }
  }
  .white {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.95;
    z-index: 2;
    .loadWhite {
      height: px2rem(50px);
      width: px2rem(50px);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50%;
      margin-top: px2rem(-80px);
    }
    .propgr {
      height: 100%;
      font-size: px2rem(14px);
      text-align: center;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .white2 {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.95;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .kpLoad {
      width: px2rem(294px);
      height: px2rem(294px);
    }
  }
}