@import "../../../px2rem.scss";

.gameToEarnContainer {
    padding: 20px px2rem(20px);
    .text {
        font-family: "SFCompactDisplayBlod";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(14px);
        line-height: px2rem(18px);
        /* or 129% */

        color: #fff9e8;
        label {
            color: #fd4f05;
            font-weight: 500;
        }
    }

    .imgs {
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(5, px2rem(53px));
        column-gap: px2rem(18px);
        row-gap: px2rem(36px);
        img {
            width: px2rem(53px);
            height: px2rem(53px);
        }
    }

    .kefus {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            margin-right: px2rem(20px);
            width: px2rem(40px);
            height:  px2rem(40px);
        }
        >img:last-child{
            margin-right:0;
        }
    }
}
