.pcTrade {
  height: 100vh;
  width: 100%;
  background: rgb(18, 14, 29);
  position: fixed;
  left: 0;
  top: 70px;
  z-index: 21;
  .tradeContent {
    height: 180px;
    width: 420px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
    margin-top: -180px;
    img {
      width: 420px;
      height: 131px;
    }
    .remark {
      width: 510px;
      text-align: center;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      font-family: "SFCompactDisplayLight";
      font-style: normal;
      padding-top: 21px;
      margin-left: -50px;
      span {
        color: #FDCC05;
        cursor: pointer;
      }
    }
  }
}