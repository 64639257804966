.bnbActivity {
  height: calc(100% - 68px);
  width: 100%;
  background: rgb(18, 14, 29);
  margin-top: 70px;
  position: fixed;
  left: 0;
  overflow: auto;
  .masks {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: #1e1e1e;
    opacity: 0.9;
    z-index: 100;
    .loadWhite {
      width: 50px;
      height: 50px;
      position: absolute;
      top: calc(50% - 60px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .bnbInner {
    height: 100%;
    min-width: 1520px;
    background: url("../../../assets/images/home/bolang.png") no-repeat;
    background-size: 100% 800px;
    background-position-y: 0px;
    .remark {
      text-align: center;
      background: linear-gradient(269.94deg, #fdcc05 3.14%, #3cd049 97.38%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      font-family: "WhiteNTBold";
    }
    .box {
      min-width: 1520px;
      height: 311px;
      display: flex;
      justify-content: center;
      margin-top: 80px;
      .walletRemark {
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        text-align: center;
        color: #ffffff;
        padding-bottom: 40px;
      }
      .kf {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        cursor: pointer;
        img {
          width: 33px;
          height: 30px;
        }
      }
      .kf:hover {
        background-image: linear-gradient(to right, #c5fbff, #debdff, #ffd745);
      }
      .bnbBox {
        width: 311px;
        height: 311px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 11.13%, rgba(255, 255, 255, 0.1) 100%);
        box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
        backdrop-filter: blur(45px);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .boxInner {
          .boxImage {
            width: 270px;
            height: 270px;
            transform: translateY(30px);
          }
          .openBtn {
            width: 220px;
            height: 60px;
            background: linear-gradient(269.93deg, #fdcc05 0.93%, #feef67 127.01%);
            box-shadow: 0px 4px 20px rgba(254, 206, 9, 0.3);
            border-radius: 40.704px;
            text-align: center;
            line-height: 60px;
            color: #623b00;
            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            transform: translateY(80px);
            margin: 0 auto;
            cursor: pointer;
          }
          .openBtn.disabled {
            background: #bbbbbb;
            color: #ffffff;
            cursor: no-drop;
          }
        }
      }
    }
    .player {
      min-width: 1520px;
      position: relative;
      transform: translateY(-300px);
      .play1 {
        width: 387px;
        height: 400px;
        position: absolute;
        top: 0px;
        left: 30px;
      }
      .play2 {
        width: 532px;
        height: 466px;
        position: absolute;
        right: 80px;
        top: 0px;
      }
      .qiu1 {
        width: 138px;
        height: 154px;
        position: absolute;
        right: 130px;
        top: -150px;
      }
      .qiu2 {
        width: 80px;
        height: 69px;
        position: absolute;
        left: 300px;
        top: 300px;
      }
    }
  }
}
