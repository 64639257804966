.recordDialog {
  .ant-modal {
    width: 486px !important;
    height: 568px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #fece1e;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
    margin-top: 100px;
  }
  .ant-modal-content {
    width: 486px;
    height: 568px;
    background: none;
 
  }
  .ant-modal-body {
    width: 486px;
    height: 568px;
    background: none;
    padding-top:75px;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #fece1e;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .recordContent {
    height: 100%;
    width: 100%;
    overflow: auto;
    // padding-top: 50px;
    .header {
      width: 389px;
      height: 84px;
      margin: 0 auto;
      background: url("../../../assets/images/home/recordImg.png") no-repeat;
      background-size: 389px 84px;
      position: absolute;
      top: -20px;
      left: 50px;
    }
    .no {
      height: calc(100% - 20px);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 20px;
      font-weight: 500;
      font-family: "SFCompactDisplayLight";
    }
    .detailContent {
      height: 100%;
      // margin-top: 50px;
    }
    .detail {
      width: 408px;
      height: 102px;
      background: rgba(18, 14, 29, 0.3);
      border-radius: 10px;
      margin: 0 auto;
      margin-bottom: 16px;
      line-height: 102px;
      padding: 0 30px;
      color: #ffffff;
      font-family: "SFCompactDisplayLight";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: auto;
      .box {
        height: 70px;
        width: 70px;
        background: url("../../../assets/images/home/christmasBox.png") no-repeat;
        background-size: 70px 70px;
        img {
          height: 60px;
          width: 60px;
          border: 3px solid #ab1b23;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          margin-top: 5px;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    background: 0;
    height: 20px;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb:vertical {
    background: rgba(255, 249, 232, 0.3);
    border-radius: 8px;
  }
}
