.balanceDialog {
  .ant-modal {
    width: 536px !important;
    height: 451px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FECE1E;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
  }
  .ant-modal-content {
    width: 536px;
    height: 451px;
    background: none;
  }
  .ant-modal-body {
    width: 536px;
    height: 451px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #FECE1E;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .dialogContent {
    height: 100%;
    width: 100%;
    .dialogHeader {
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      text-transform: uppercase;
      color: #FFF9E8;
      text-align: center;
      padding: 0 0 39px 0;
    }
    .dialogNeed {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #FFF9E8;
      font-family: 'SFCompactDisplayLight';
      span {
        font-family: 'SFCompactDisplayLight';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: #FFF9E8;
      }
      i {
        font-family: 'SFCompactDisplayLight';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: #FECE1E;
      }
    }
    .dialogRemark {
      padding: 40px 40px 17px 40px;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #FFF9E8;
      display: flex;
      justify-content: space-between;
      div:first-child {
        font-size: 20px;
        font-weight: 600;
      }
    }
    .invitationCode {
      width: 460px;
      height: 50px;
      background: rgba(18, 14, 29, 0.3);
      border-radius: 10px;
      line-height: 50px;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgba(183, 181, 165, 0.5);
      margin: 0 auto;
      padding-left: 22px;
      display: flex;
      justify-content: space-between;
      div {
        width: 400px;
      }
      img {
        height: 30px;
        width: 28px;
        margin: 10px 10px 0 0;
        cursor: pointer;
      }
    }
    .btn {
      width: 180px;
      height: 50px;
      background: linear-gradient(269.93deg, #FDCC05 0.93%, #FEEF67 127.01%);
      box-shadow: 0px 4px 20px rgba(254, 206, 9, 0.3);
      border-radius: 40.704px;
      font-family: 'SFCompactDisplay';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 50px;
      text-align: center;
      color: #623B00;
      margin: 20px auto;
      cursor: pointer;
    }
    .btns {
      width: 180px;
      height: 50px;
      background: linear-gradient(269.93deg, #FDCC05 0.93%, #FEEF67 127.01%);
      opacity: 0.3;
      box-shadow: 0px 4px 20px rgba(254, 206, 9, 0.3);
      border-radius: 40.704px;
      font-family: 'SFCompactDisplay';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 50px;
      text-align: center;
      color: #623B00;
      margin: 20px auto;
      cursor: pointer;
    }
    .addText {
      display: block;
      text-align: center;
      color: #FECE1E;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      font-family: 'SFCompactDisplay';
      cursor: pointer;
    }
    .addText:hover {
      color: #FECE1E;
    }
  }
}