
.resultDialog {
  .how_to_sell { 
    margin-right: 30px;
    font-family: "SFCompactDisplay";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    /* identical to box height, or 89% */
  
    text-align: center;
  
    color: #fece1e;
  
    display: flex;
    align-items: center;
    justify-content: center;
    >img {
      width: 17px;
      height: 17px;
      margin-right: 6px;
    }
  }
  .how_to_sell:hover{
    color: #fece1e;
  }
  .saleLeft {
    display: flex;
    justify-content: space-between;
    margin-left: -136px;
  }
  .game {
    width: 227px;
    height: 50px;
    background: url("../../../assets/images/result/btn.png") no-repeat;
    background-size: 227px 50px;
    text-align: center;
    color: #623B00;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    font-size: 18px;
    font-family: "SFCompactDisplay";
    cursor: pointer;
  }
  .ant-modal {
    width: 931px !important;
    height: 609px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #fece1e;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
    position: relative;
    top: 150px;
  }
  .ant-modal-content {
    width: 931px;
    height: 609px;
    background: none;
    background: url("../../../assets/images/home/rules.png") no-repeat;
    background-size: 931px 609px;
    position: relative;
  }
  .ant-modal-body {
    width: 931px;
    height: 609px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #fece1e;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .resultContent {
    width: 100%;
    height: 100%;
    .title {
      width: 338px;
      height: 57px;
      background: url("../../../assets/images/result/bg_congratulations@2x.png") no-repeat;
      background-size: 338px 57px;
      position: absolute;
      top: -32px;
      left: 296px;
      z-index: 25;
    }
    .sellTopImg {
      height: 250px;
      width: 100%;
      z-index: -1;
      background: url("../../../assets/images/result/sellTop.png") no-repeat;
      background-size: 1100px 240px;
      position: absolute;
      top: -160px;
    }
    .jb1 {
      height: 160px;
      width: 160px;
      background: url("../../../assets/images/result/jb1.png") no-repeat;
      background-size: 160px 160px;
      position: absolute;
      right: -130px;
      top: -80px;
    }
    .jb2 {
      height: 160px;
      width: 160px;
      background: url("../../../assets/images/result/jb2.png") no-repeat;
      background-size: 160px 160px;
      position: absolute;
      left: -90px;
      top: 0px;
    }
    .congratulations {
      font-family: "SFCompactDisplayBlod";
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 65px;
      text-transform: uppercase;
      color: #623b00;
      position: absolute;
      top: -36px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 30;
      width: 468px;
      text-align: center;
    }
    .share {
      box-sizing: border-box;
      width: 100px;
      height: 40px;
      border: 1px solid #fece1e;
      border-radius: 32px;
      text-align: center;
      line-height: 40px;
      color: #fece1e;
      font-family: "SFCompactDisplayLight";
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      float: right;
      margin: 47px 67px 0 0;
      cursor: pointer;
    }
    .only {
      height: 100%;
      width: 100%;
      .one {
        height: 390px;
        width: 100%;
        padding-top: 150px;
        background: url("../../../assets/images/home/maghe_kuang.png") no-repeat;
        background-size: 246px 246px;
        background-position-x: 320px;
        // background-position-y: 156px;
        background-position-y: 126px;
        >img {
          width: 160px;
          height: 160px;
          // margin-top: 50px;
          margin-top: 20px;
          border-radius: 6px;
          margin-left: 363px;
        }
        .gamingOne {
          width: 126px;
          height: 58px;
          border: 0.5px dashed #fff9e8;
          border-radius: 6px;
          text-align: center;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #fff9e8;
          position: absolute;
          bottom: 90px;
          bottom: 130px;
          left: 406px;
          padding-top: 10px;
        }
        .game {
          margin: 0 auto;
          margin-top: 90px;
        }
      }
      .two {
        height: 440px;
        width: 100%;
        padding-top: 150px;
        .sellName {
          color: #fff9e8;
          font-weight: 400;
          font-size: 18px;
          padding-top: 35px;
          position: absolute;
          bottom: 186px;
          left: 50%;
          transform: translateX(-50%);
        }
        >img {
          width: 167px;
          height: 167px;
          margin-top: 50px;
          border-radius: 6px;
          position: absolute;
          left: 381px;
          top: 150px;
        }
        .bgImage {
          width: 246px;
          height: 246px;
          position: absolute;
          top: 110px;
          left: 50%;
          transform: translateX(-50%);
        }
        .disSell {
          width: 152px;
          height: 50px;
          margin: 0 auto;
          margin-top: 15px;
          text-align: center;
          font-size: 12px;
          color: #623b00;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          border: 1px dashed #fece1e;
          border-radius: 6px;
          background: rgba(253, 204, 5, 0.2);
          color: #fdcc05;
          margin-top: 266px;
          span {
            font-size: 18px;
          }
        }
        .selectImg {
          height: 25px;
          width: 25px;
          position: absolute;
          bottom: 0;
          right: 0;
          top: 310px;
          left: 630px;
        }
        .bottomReamark {
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #fece1e;
          text-align: center;
          padding-top: 20px;
          span {
            color: #fd4f05;
            font-weight: 600;
          }
        }
        .saleBtns {
          display: flex;
          justify-content: center;
          margin-top: 24px;
          .bottomBtn {
            margin-left: -140px;
            display: flex;
            .kong {
              width: 227px;
              height: 50px;
              // background: url("../../../assets/images/result/btn.png") no-repeat;
              background: url('../../../assets/images/result/xian_btn.png') no-repeat;
              background-size: 227px 50px;
              text-align: center;
              line-height: 46px;
              color: #FECE1E;
              // color: #623B00;
              font-style: normal;
              font-weight: 700;
              font-size: 18px; 
              font-family: "SFCompactDisplay"; 
              cursor: pointer;
              margin-right: 80px;
              img {
                width: 22px;
                height: 22px;
                margin-right: 9px;
                margin-top: -4px;
              }
            }
          }
          .sellBtn {
            width: 227px;
            height: 50px;
            background: url("../../../assets/images/result/btn.png") no-repeat;
            // background: url('../../../assets/images/result/xian_btn.png') no-repeat;
            background-size: 227px 50px;
            text-align: center;
            line-height: 46px;
            // color: #FECE1E;
            color: #623B00;
            font-style: normal;
            font-weight: 700;
            font-size: 18px; 
            font-family: "SFCompactDisplay"; 
            cursor: pointer;
            margin-right: 80px;
            img {
              width: 22px;
              height: 22px;
              margin-right: 9px;
              margin-top: -4px;
            }
          }
        }
      }
      .loadIcon {
        font-size: 50px;
        height: 50px;
        color: white;
        position: absolute;
        top: 250px;
        left: 440px;
      }
      .loadIcon5 {
        font-size: 50px;
        height: 50px;
        color: white;
        position: absolute;
        bottom: 120px;
        left: 430px;
      }
      .loadIcon10 {
        font-size: 50px;
        height: 50px;
        color: white;
        position: absolute;
        top: 150px;
        left: 425px;
      }
      .gaming {
        width: 126px;
        height: 58px;
        border: 0.5px dashed #fff9e8;
        border-radius: 6px;
        text-align: center;
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #fff9e8;
        position: absolute;
        bottom: 150px;
        left: 486px;
        padding-top: 10px;
      }
    }
    .sellTop {
      text-align: center;
      position: absolute;
      width: 550px;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #fece1e;
      font-family: "SFCompactDisplayLight";
      .price {
        font-size: 26px;
        font-weight: 600;
      }
    }
    .delayed {
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      color: #fd6b10;
      font-family: "SFCompactDisplayLight";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
    .sellName {
      text-align: center;
      color: #fff9e8;
      font-weight: 400;
      font-size: 18px;
      padding-top: 35px;
    }
    .gmbStyle {
      height: 250px;
      width: 883px;
      position: absolute;
      top: 180px;
      display: flex;
      justify-content: space-around;
      .el-icon-loading {
        font-size: 50px;
        height: 50px;
        color: white;
        position: absolute;
        top: 80px;
        left: 523px;
      }
      .list {
        width: 1050px;
        height: 250px;
        display: flex;
        justify-content: space-around;
      }
      .itemStyle {
        float: left;
        position: relative;
      }
      .resultHm {
        height: 150px;
        width: 150px;
        position: relative;
        .bgImage {
          height: 150px;
          width: 150px;
          position: absolute;
          top: 0;
          left: -25px;
        }
        .selectImg {
          height: 25px;
          width: 25px;
          position: absolute;
          top: 114px;
          right: 10px;
        }
        .disSell {
          width: 126px;
          height: 50px;
          text-align: center;
          font-size: 14px;
          color: #623b00;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          border: 1px dashed #fece1e;
          border-radius: 6px;
          background: rgba(253, 204, 5, 0.2);
          color: #fdcc05;
          position: absolute;
          left: 12px;
          top: 171px;
          span {
            font-size: 14px;
          }
        }
        >img {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          position: absolute;
          top: 26px;
          margin-left: 25px;
          border-radius: 10px;
        }
      }
      .resultImg {
        height: 150px;
        width: 150px;
        background: url("../../../assets/images/home/maghe_kuang.png") no-repeat;
        background-size: 150px 150px;
        position: relative;
        >img {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          position: absolute;
          top: 26px;
          margin-left: 25px;
          border-radius: 10px;
        }
        .gaming {
          width: 126px;
          height: 50px;
          border: 0.5px dashed #fff9e8;
          border-radius: 6px;
          text-align: center;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #fff9e8;
          position: absolute;
          bottom: -70px;
          left: 12px;
          padding-top: 0;
          .gameValue {
            font-size: 14px;
          }
        }
      }
      .sellName {
        padding-top: 144px;
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
      }
      .saleBtns {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        width: 900px;
        position: absolute;
        bottom: -95px;
        .sellBtn {
          width: 227px;
          height: 50px;
          background: url("../../../assets/images/result/btn.png") no-repeat;
          // background: url('../../../assets/images/result/xian_btn.png') no-repeat;
          background-size: 227px 50px;
          text-align: center;
          line-height: 46px;
          // color: #FECE1E;
          color: #623B00;
          font-style: normal;
          font-weight: 700;
          font-size: 18px; 
          font-family: "SFCompactDisplay"; 
          cursor: pointer;
          margin-right: 80px;
          >img {
            width: 22px;
            height: 22px;
            margin-right: 9px;
            margin-top: -4px;
          }
        }
      }
      .bottomReamark {
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #fece1e;
        text-align: center;
        padding-top: 125px;
        width: 900px;
        position: absolute;
        top: 150px;
        span {
          color: #fd4f05;
          font-weight: 600;
        }
      }
    }
    .gmbStyle10 {
      height: 370px;
      width: 883px;
      position: absolute;
      top: 110px;
      display: flex;
      justify-content: space-around;
      padding: 0 50px;
      flex-wrap: wrap;
      .el-icon-loading {
        font-size: 50px;
        height: 50px;
        color: white;
        margin-top: 100px;
      }
      .list {
        width: 1050px;
        height: 370px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        justify-items: start;
        .saleBtns {
          display: flex;
          justify-content: center;
          margin-top: 50px;
          width: 900px;
          position: absolute;
          bottom: -90px;
          .sellBtn {
            width: 227px;
            height: 50px;
            background: url("../../../assets/images/result/btn.png") no-repeat;
            // background: url('../../../assets/images/result/xian_btn.png') no-repeat;
            background-size: 227px 50px;
            text-align: center;
            line-height: 46px;
            // color: #FECE1E;
            color: #623B00;
            font-style: normal;
            font-weight: 700;
            font-size: 18px; 
            font-family: "SFCompactDisplay"; 
            cursor: pointer;
            margin-right: 80px;
            img {
              width: 22px;
              height: 22px;
              margin-right: 9px;
              margin-top: -4px;
            }
          }
        }
        .bottomReamark {
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #fece1e;
          text-align: center;
          padding-top: 230px;
          width: 900px;
          position: absolute;
          top: 150px;
          span {
            color: #fd4f05;
            font-weight: 600;
          }
        }
      }
      .gmbListStyle {
        height: 150px;
        width: 20%;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        z-index: 2;
        .resultHm {
          height: 110px;
          width: 110px;
          .bgImage {
            height: 110px;
            width: 110px;
            position: absolute;
            top: 21px;
            left: 13px;
          }
          .disSell {
            width: 100px;
            height: 40px;
            text-align: center;
            font-size: 12px;
            color: #623b00;
            font-family: "SFCompactDisplayLight";
            font-style: normal;
            font-weight: 400;
            border: 1px dashed #fece1e;
            border-radius: 6px;
            background: rgba(253, 204, 5, 0.2);
            color: #fdcc05;
            position: absolute;
            left: 19px;
            top: 146px;
            span {
              font-size: 12px;
            }
          }
          >img {
            width: 70px;
            height: 70px;
            margin: 0 auto;
            position: absolute;
            top: 41px;
            left: 33px;
            border-radius: 10px;
          }
          .selectImg {
            height: 20px;
            width: 20px;
            position: absolute;
            top: 104px;
            right: -50px;
          }
        }
        .resultImg {
          height: 110px;
          width: 110px;
          background: url("../../../assets/images/home/maghe_kuang.png") no-repeat;
          background-size: 110px 110px;
          margin: 4px 0 0 -20px;
          >img {
            width: 70px;
            height: 70px;
            margin: 0 auto;
            position: absolute;
            top: 41px;
            left: 33px;
            border-radius: 10px;
          }
          .gaming {
            width: 100px;
            height: 40px;
            border: 0.5px dashed #fff9e8;
            border-radius: 6px;
            text-align: center;
            font-family: "SFCompactDisplayLight";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #fff9e8;
            position: absolute;
            bottom: -36px;
            left: 20px;
            padding: 0;
          }
        }
        .sellName {
          padding-top: 100px;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
    .only1 {
      height: 100%;
      width: 100%;
      .sellTop {
        text-align: center;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #fece1e;
        font-family: "SFCompactDisplayLight";
        .price {
          font-size: 26px;
          font-weight: 600;
        }
      }
      .delayed {
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: #fd6b10;
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .gmbStyle30 {
      height: 400px;
      width: 883px;
      position: absolute;
      top: 120px;
      display: flex;
      justify-content: space-around;
      padding: 0 50px;
      flex-wrap: wrap;
      .el-icon-loading {
        color: white;
        font-size: 50px;
        height: 50px;
        position: absolute;
        top: 0px;
      }
      .allHm {
        width: 120px;
        height: 170px;
        float: left;
      }
      .hm {
        height: 400px;
        width: 883px;
        padding: 0 0 0 105px;
        overflow: hidden;
        z-index: 11;
        .hmList {
          width: 100px;
          height: 170px;
          float: left;
          margin-right: 18px;
          margin-bottom: 12px;
          position: relative;
          .bgImage {
            height: 90px;
            width: 90px;
          }
          .hmImg {
            height: 60px;
            width: 60px;
            border-radius: 6px;
            margin: 0 auto;
            position: absolute;
            top: 14px;
            left: 15px;
          }
          .hmName {
            text-transform: uppercase;
            font-family: "SFCompactDisplayLight";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #fff9e8;
            text-align: center;
            padding-top: 12px;
            position: absolute;
            top: 76px;
            left: 12px;
          }
          .sell {
            width: 90px;
            height: 38px;
            text-align: center;
            font-size: 12px;
            color: #fdcc05;
            font-family: "SFCompactDisplayLight";
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
            margin: 24px 0 0 2px;
            background: rgba(253, 204, 5, 0.2);
            border: 1px dashed #fece1e;
            border-radius: 4px;
          }
          .selectImg {
            position: absolute;
            right: 12px;
            top: 64px;
            width: 20px;
            height: 20px;
          }
        }
        .mhList {
          width: 100px;
          height: 170px;
          float: left;
          margin-right: 18px;
          margin-left: -3px;
          background: url("../../../assets/images/home/maghe_kuang.png") no-repeat;
          background-size: 90px 90px;
          background-position-x: 5px;
          position: relative;
          .hmImg {
            height: 60px;
            width: 60px;
            border-radius: 6px;
            margin: 0 auto;
            position: absolute;
            top: 14px;
            left: 20px;
          }
          .sell {
            width: 90px;
            height: 38px;
            text-align: center;
            font-size: 12px;
            color: #fff9e8;
            font-family: "SFCompactDisplayLight";
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
            margin: 114px 0 0 6px;
            border: 0.5px dashed #fff9e8;
            border-radius: 4px;
          }
          .hmName {
            text-transform: uppercase;
            font-family: "SFCompactDisplayLight";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #fff9e8;
            text-align: center;
            padding-top: 12px;
            position: absolute;
            top: 76px;
            left: 16px;
          }
        }
        .pagination {
          position: absolute;
          height: 70px;
          width: 566px;
          bottom: -20px;
          text-align: center;
          .nextBtn {
            width: 96px;
            height: 30px;
            left: 556px;
            top: 683px;
            border: 1px solid #fff9e8;
            border-radius: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: #fff9e8;
            font-family: "SFCompactDisplayLight";
            font-style: normal;
            margin-top: -3px;
          }
          .ant-pagination-item:hover a {
            color: #fdcc05 !important;
            line-height: 30px;
            font-family: "SFCompactDisplayLight";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
          .ant-pagination-item {
            border-radius: 8px;
            border: 1px solid #fff9e8;
            border-radius: 8px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            background: none;
            color: #fff9e8;
            margin-top: -8px;
            a {
              color: #fff9e8;
              line-height: 30px;
              font-family: "SFCompactDisplayLight";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
            }
          }
          .ant-pagination-item-active {
            border-radius: 8px;
            backdrop-filter: blur(2px);
            box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
            border: 1px solid #fdcc05;
            background: rgba(255, 255, 255, 0.5);
            width: 30px;
            height: 30px;
            text-align: center;
            a {
              color: #fdcc05;
              line-height: 30px;
              font-family: "SFCompactDisplayLight";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
            }
          }
        }
      }
      .saleBtns {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        width: 566px;
        position: absolute;
        bottom: -54px;
        .sellBtn {
          width: 227px;
          height: 50px;
          background: url("../../../assets/images/result/btn.png") no-repeat;
          // background: url('../../../assets/images/result/xian_btn.png') no-repeat;
          background-size: 227px 50px;
          text-align: center;
          line-height: 46px;
          // color: #FECE1E;
          color: #623B00;
          font-style: normal;
          font-weight: 700;
          font-size: 18px; 
          font-family: "SFCompactDisplay"; 
          cursor: pointer;
          margin-right: 80px;
          >img {
            width: 22px;
            height: 22px;
            margin-right: 9px;
            margin-top: -4px;
          }
        }
      }
      .bottomReamark {
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #fece1e;
        text-align: center;
        padding-top: 230px;
        width: 566px;
        position: absolute;
        top: 170px;
        span {
          color: #fd4f05;
          font-weight: 600;
        }
      }
    }
    .loadIcon30 {
      font-size: 50px;
      height: 50px;
      color: white;
      position: absolute;
      top: 150px;
      left: 425px;
    }
  }
}
