@import "../../../px2rem.scss";
.congratulations2Container {
    // background-color: #37364d;
    .topCard {
        width: 100%;
        height: px2rem(92px);
        background-image: url("../../../assets/images/h5/collection.png");
        background-repeat: repeat;
        background-size: 100%;
        position: relative;
        .topPanel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: px2rem(13px) px2rem(18px);
        }
    }
    .titleCard {
        position: relative;
        width: 100%;
        height: fit-content;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0) 81.9%);
        border-radius: px2rem(20px);
        border-top: 1px solid #fece1e;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .text {
            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 600;
            font-size: px2rem(14px);
            /* identical to box height, or 143% */
            text-align: center;
            color: #fece1e;
            padding-top: px2rem(24px);
        }

        .title {
            position: absolute;
            transform: translate(-50%, -55%);
            top: 0px;
            left: 50%;
            height: px2rem(38px);
            width: px2rem(276px);
            background-image: url("../../../assets/images/h5/concetionTitlebg.svg");
            background-repeat: repeat;
            background-size: 100%;

            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 700;
            font-size: px2rem(20px);
            /* or 150% */

            text-transform: uppercase;

            color: #623b00;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .subText {
        margin-top: px2rem(4px);
        text-align: center;
        font-family: "SFCompactDisplayrightLight";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(12px);
        line-height: px2rem(14px);
        text-align: center;
        color: #fece1e;
    }

    .ntfsContainer {
        display: grid;
        grid-template-columns: repeat(1, px2rem(232px));
        column-gap: px2rem(30px);
        row-gap: px2rem(36px);
        justify-content: center;
        margin-top: px2rem(24px);
        padding-bottom: px2rem(24px);
        .h5NTFItemContainer {
            width: px2rem(232px);
            // height: px2rem(232px);
            .ntmImg {
                width: px2rem(267px);
                height: px2rem(267px);
                .toubiao {
                    position: absolute;
                    width: px2rem(36px);
                    height: px2rem(44px);
                    top: 0px;
                }

                img {
                    width: px2rem(186px);
                    height: px2rem(186px);
                    border-radius: px2rem(12px);
                }
            }
            .title {
                font-size: px2rem(18px);
            }
            .price {
                width: px2rem(126px);
                height: px2rem(38px);
                font-size: px2rem(14px);
            }
        }
    }
    .btns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn {
            width: px2rem(253px);
            height: px2rem(46px);

            font-family: "SF Compact Display";
            font-style: normal;
            font-weight: 700;
            font-size: px2rem(18px);
            text-align: center;
            text-transform: uppercase;
            border-radius: px2rem(30px);

            display: flex;
            align-items: center;
            justify-content: center;
        }
        .continue {
            background: #fdcc05;
            box-shadow: 0px 4px 30px rgba(245, 181, 46, 0.2), inset 1px 1px 2px rgba(255, 247, 213, 0.3);

            /* identical to box height, or 89% */

            color: #623b00;
        }
        .eLEMent {
            border: 1px solid #fece1e;
            // box-shadow: inset -2px -1px 1px rgba(255, 243, 221, 0.6), inset 1px 1px 2px rgba(255, 247, 213, 0.3);
            filter: drop-shadow(0px 4px 30px rgba(245, 181, 46, 0.2));
            margin-top: px2rem(16px);

            color: #fece1e;
            img {
                width: px2rem(20px);
                height: px2rem(20px);
                margin-right: px2rem(5px);
            }
        }
        .how {
            margin-top: px2rem(12px);

            font-family: "SF Compact Display";
            font-style: normal;
            font-weight: 400;
            font-size: px2rem(14px);
            text-align: center;

            color: #fece1e;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: px2rem(12px);
                height: px2rem(12px);
                margin-right: px2rem(5px);
            }
        }
    }
    .noData {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
