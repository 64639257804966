.christmas {
  height: calc(100% - 68px);
  width: 100%;
  background: rgb(18, 14, 29);
  margin-top: 70px;
  position: fixed;
  left: 0;
  overflow: auto;
  .inner {
    height: 100vh;
    width: 1520px;
    margin: 0 auto;
    .activeTop {
      height: 750px;
      width: 100%;
      padding: 78px 121px 0 124px;
      display: flex;
      justify-content: space-between;
      background: url('../../../assets/images/home/vector.png') no-repeat;
      background-size: 1308px 548px;
      background-position-x: 100px;
      background-position-y: 110px;
      .left {
        width: 50%;
        .vip {
          height: 402px;
          background: linear-gradient(119.2deg, #FFFFFF 13.43%, #FFB960 81.68%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          font-style: normal;
          .dis {
            font-family: 'SFCompactDisplayBlod';
            font-weight: 900;
            font-size: 40px;
          }
          .back {
            font-weight: 900;
            font-size: 90px;
            font-family: 'SFCompactDisplayBlod';
            line-height: 100px;
          }
        }
        .remark {
          font-family: 'SFCompactDisplayBlod';
          font-style: normal;
          font-weight: 900;
          font-size: 40px;
          color: #FDCC05;
          height: 210px;
          line-height: 70px;
          span {
            font-size: 60px;
          }
        }
        .middle {
          font-family: 'SFCompactDisplayLight';
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
          color: #FFFFFF;
          padding: 24px 0;
        }
        .time {
          font-family: 'SFCompactDisplayLight';
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
          color: #FFFFFF;
        }
        .snow {
          margin-top: -350px;
          margin-left: 400px;
        }
        .man {
          width: 602px;
          height: 301px;
          margin-top: -40px;
        }
      }
      .right {
        width: 50%;
        text-align: center;
        font-family: 'SFCompactDisplayLight';
        font-style: normal;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .table1 {
          margin-top: 66px;
        }
        .lingdang {
          width: 130px;
          height: 144px;
          position: absolute;
          z-index: 10;
          top: 0;
          right: -50px;
        }
        .swiper-nft {
          width: 100%;
          margin: 0 auto;
          .zebra {
            width: 386px;
            height: 400px;
            background: url('../../../assets/images/home/christmasBox.png') no-repeat;
            background-size: 386px 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-position-x: 50%;
            .nftsImg {
              height: 352px;
              width: 332px;
              border: 3px solid #AB1B23;
              border-radius: 20px;
            }
          }
          .swiper-button-prev::after {
            display: none;
          }
          .swiper-button-prev {
            height: 36px;
            width: 36px;
            background: url('../../../assets/images/result/left.png') no-repeat;
            background-size: 36px 36px;
            position: absolute;
            left: 0;
          }
          .swiper-button-next::after {
            display: none;
          }
          .swiper-button-next {
            height: 36px;
            width: 36px;
            background: url('../../../assets/images/result/right.png') no-repeat;
            background-size: 36px 36px;
            position: absolute;
            right: 0;
          }
        }
        
        .stock {
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          color: #FECE1E;
          padding: 16px 0;
        }
        .activeButton {
          width: 363px;
          height: 70px;
          background: #BBBBBB;
          border-radius: 40px;
          color: #FFFFFF;
          font-weight: 700;
          font-size: 36px;
          line-height: 70px;
          z-index: 2;
          position: relative;
          margin: 0 auto;
          font-family: 'SFCompactDisplay';
          cursor: no-drop;
        }
        .lightButton {
          width: 363px;
          height: 70px;
          background: linear-gradient(119.2deg, #FFFFFF 13.43%, #FFB960 81.68%);
          border-radius: 40px;
          color: #623B00;
          font-weight: 700;
          font-size: 36px;
          line-height: 70px;
          z-index: 2;
          position: relative;
          margin: 0 auto;
          cursor: pointer;
        }
        .mintEnd {
          position: relative;
          z-index: 2;
          color: #DBD3BD;
          font-weight: 400;
          font-size: 24px;
          padding-top: 22px;
        }
        .table {
          width: 494px;
          height: 200px;
          margin-top: 18px;
          z-index: 1;
          margin-top: -120px;
        }
      }
    }
    .activeBottom {
      // height: 1290px;
      width: 100%;
      text-align: center;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      .title {
        margin: 124px 0 20px 0;
      }
      .receive {
        color: #FFFFFF;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
      }
      .iconPie {
        width: 485px;
        height: 70px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        .pie {
          height: 13px;
          width: 13px;
          border-radius: 50%;
          background: rgba(103, 86, 122, 0.7);
        }
        .rece {
          display: flex;
          width: 443px;
          height: 70px;
          background: rgba(103, 86, 122, 0.4);
          border: 1px solid #67567A;
          border-radius: 40px;
          margin: 0 auto;
          justify-content: space-between;
          align-items: center;
          padding: 0 30px;
          .record {
            color: #FFB960;
            font-weight: 400;
            font-size: 20px;
            cursor: pointer;
          }
          .number {
            color: #FFFFFF;
            font-weight: 400;
            font-size: 20px;
            span {
              color: #D82146;
            }
          }
        }
      }
      .swiper-container1 {
        height: 310px;
        width: 1193px;
        margin: 0 auto;
        margin-top: 80px;
        margin-bottom: 80px;
        .prizeBox {
          width: 25%;
          height: 310px;
          .kuang {
            height: 166px;
            width: 164px;
            border: 3px solid #AB1B23;
            border-radius: 20px;
            margin-top: -230px;
            z-index: 2;
            position: relative;
          }
          .box {
            height: 250px;
            width: 260px;
            background: url('../../..//assets/images/home/sdBox.png') no-repeat;
            background-size: 260px 250px;
            transform: translateX(32px);
          }
          .prizeBtn {
            // width: 138px;
            height: 38px;
            line-height: 38px;
            // border: 1px solid #67567A;
            // border-radius: 20px;
            color: #FFB960;
            font-weight: 500;
            font-size: 20px;
            margin: 0 auto;
            cursor: pointer;
          }
        }
        .swiper-button-prev::after {
          display: none;
        }
        .swiper-button-prev {
          height: 36px;
          width: 36px;
          background: url('../../../assets/images/result/left.png') no-repeat;
          background-size: 36px 36px;
          position: absolute;
          left: 0;
        }
        .swiper-button-next::after {
          display: none;
        }
        .swiper-button-next {
          height: 36px;
          width: 36px;
          background: url('../../../assets/images/result/right.png') no-repeat;
          background-size: 36px 36px;
          position: absolute;
          right: 0;
        }
      }
      .extractBtn {
        width: 286px;
        height: 60px;
        background: linear-gradient(119.2deg, #FFFFFF 13.43%, #FFB960 81.68%);
        border-radius: 40.704px;
        line-height: 60px;
        margin: 0 auto;
        margin-bottom: 60px;
        color: #623B00;
        font-weight: 700;
        font-size: 30px;
        cursor: pointer;
        font-family: 'SFCompactDisplay';
      }
      .extractBtn.disabled{
        cursor:no-drop;
        opacity: 0.5;
      }
      .gift {
        margin: 0 auto;
        width: 276px;
        height: 35px;
        border: none;
        padding: 0 40px;
        background: rgb(18, 14, 29);
        transform: translateY(10px);
      }
      .rule {
        width: 1195px;
        margin: 0 auto;
        height: 297px;
        border: 1px solid #67567A;
        border-radius: 20px;
        text-align: center;
        color: #FFFFFF;
        font-family: 'SFCompactDisplayLight';
        font-style: normal;
        font-size: 24px;
        font-weight: 400;
        padding: 38px 0;
        margin-bottom: 90px;
        div {
          padding-bottom: 8px;
        }
      }
    }
  }
}