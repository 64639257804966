.h5footer {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 20px;
    color:#ffffff;
    font-size: 10px;
    .text {
        > label:first-child {
            margin-right: 10px;
        }
    }
    .icon{
        >img:first-child{
            margin-right: 20px;
        }
        img {
            width: 30px;
            height: 30px;
        }
    }
   
}
