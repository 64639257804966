@import '../../px2rem.scss';
.lightDivNew{
    height: px2remToPc(294px);
    width: px2remToPc(971px); 
   
    position: absolute;
    top: px2remToPc(233px);
    left: px2remToPc(122px); 
    margin-left: 0px;
}
.lightDivNew1 { 
    background: url("../../assets/images/home/pcDeng5.png") no-repeat;
    background-size: px2remToPc(971px) px2remToPc(294px);
   
}
.lightDivNew2 { 
    background: url("../../assets/images/home/pcDeng6.png") no-repeat;
    background-size: px2remToPc(971px) px2remToPc(294px);
   
}
