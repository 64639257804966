.freeWhiteDialog {
  .ant-modal {
    width: 702px !important;
    height: 540px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FECE1E;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
  }
  .ant-modal-content {
    width: 702px;
    height: 540px;
    background: none;
  }
  .ant-modal-body {
    width: 702px;
    height: 540px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #FECE1E;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .dialogContent {
    height: 100%;
    width: 100%;
    .header {
      text-align: center;
      color: #FECE1E;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      padding-bottom: 24px;
    }
    .card {
      width: 255px;
      height: 320px;
      background: linear-gradient(180deg, rgba(253, 204, 5, 0.1) 0%, rgba(253, 204, 5, 0.05) 100%);
      border-radius: 20px;
      margin: 0 auto;
      .cardInner {
        width: 235px;
        height: 320px;
        background: url('../../../assets/images/home/card-white.png') no-repeat;
        background-size: 235px 320px;
        margin: 0 auto;
        transform: translateY(8px);
        .title {
          text-align: center;
          background: linear-gradient(270deg, #FDC805 5%, #FFFEF9 52.75%, #FDC805 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          font-family: 'SFCompactDisplay';
          font-style: normal;
          font-weight: 600;
          font-size: 40px;
          padding: 48px 0 28px 0;
          margin: 0 22px;
          border-bottom: 1px dashed #FECE1E;
        }
        .remark {
          padding: 18px 18px 0 18px;
          color: #FFF9E8;
          font-family: 'SFCompactDisplayLight';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          .label {
            span {
              text-decoration: line-through;
            }
          }
        }
      }
      .bottom {
        width: 255px;
        height: 74px;
        margin-top: -30px;
        z-index: 2;
        position: relative;
      }
      .btn {
        width: 180px;
        height: 50px;
        background: linear-gradient(269.93deg, #FDCC05 0.93%, #FEEF67 127.01%);
        box-shadow: 0px 4px 20px rgba(254, 206, 9, 0.3);
        border-radius: 40.704px;
        margin: 0 auto;
        text-align: center;
        color: #623B00;
        font-family: 'SFCompactDisplayLight';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 50px;
        margin-top: 28px;
        cursor: pointer;
      }
    }
  }
}