.allLoad {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #1E1E1E;
  opacity: 0.97;
  z-index: 999;
  .loadWhite {
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 60px);
    left: 50%;
    transform: translateX(-50%);
  }
  .maskRemark {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    font-family: 'SFCompactDisplayLight';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  .spin1 {
    width: 100%;
    height: 100%;
    .ant-spin-dot {
      width: 559px;
      height: 395px;
      background: #7A4D41;
      box-shadow: 0px 5px 5px rgba(51, 33, 35, 0.8), inset -4px 3px 4px rgba(51, 33, 35, 0.5), inset 0px -1px 4px rgba(51, 33, 35, 0.5);
      border-radius: 10px;
      position: absolute;
      top: 250px;
      left: 50%;
      transform: translateX(-50%);
      .loading {
        width: 327px;
        height: 120px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -320px;
        transform: translateX(-50%);
      }
      .deng {
        transform: translateY(-166px);
      }
      .mint {
        margin-top: -6px;
      }
    }
  }
  .spin {
    width: 100%;
    height: 100%;
    .loading1 {
      width: 600px;
      height: 600px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -300px;
      transform: translateX(-50%);
    }
  }
}