@import '../../../px2rem.scss';

.mintsContent {
  width: px2remToPc(510px);
  height: px2remToPc(346px);
  margin:  0 auto;
  margin-top: px2remToPc(33px);
  background: url('../../../assets/images/home/fire.gif') no-repeat;
  background-size: px2remToPc(510px) px2remToPc(346px);
  border-radius: px2remToPc(10px);
  .inner {
    display: flex;
    justify-content: space-around;
    padding: 0 px2remToPc(50px);
  }
  .other {
    display: flex;
    justify-content: space-around;
    padding: 0 px2remToPc(30px);
  }
  .bottom {
    margin-top: px2remToPc(3px);
    .drawNumber1 {
      height: px2remToPc(30px);
      width: px2remToPc(510px);
      line-height: px2remToPc(30px);
      text-align: center;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      display: flex;
      justify-content: space-between;
      font-size: px2remToPc(16px);
      margin: 0 auto;
      color: #FFF9E8;
      position: relative;
      z-index: 10;
      padding: 0 px2remToPc(20px);
      .first {
        width: px2remToPc(300px);
        display: flex;
        justify-content: space-between;
        .total {
          width: px2remToPc(150px);
          height: 100%;
          text-align: left;
          .refresh {
            margin-left: px2remToPc(12px);
            cursor: pointer;
          }
        }
      }
      .price {
        width: px2remToPc(340px);
        text-align: right;
        padding-right: px2remToPc(10px);
      }
      .middle {
        cursor: pointer;
        .right {
          font-size: px2remToPc(12px);
        }
      }
    }
  }
  
  .cardActive {
    height: px2remToPc(294px);
    width: px2remToPc(200px);
    background: #FFE600;
    backdrop-filter: blur(px2remToPc(10px));
    border-radius: px2remToPc(10px);
    margin-top: px2remToPc(17px);
    position: relative;
    .cardImg {
      height: px2remToPc(335px);
      width: px2remToPc(240px);
      position: absolute;
      top: px2remToPc(-21px);
      left: px2remToPc(-19px);
    }
    .cardImgs {
      height: px2remToPc(294px);
      width: px2remToPc(200px);
      position: absolute;
      top: px2remToPc(-1px);
      border: px2remToPc(1px) solid #DEC501;
      border-radius: px2remToPc(10px);
      img {
        width: px2remToPc(239px);
        height: px2remToPc(334px);
        margin-left: px2remToPc(-21px);
        margin-top: px2remToPc(-20px);
      }
    }
    img {
      height: px2remToPc(246px);
      width: px2remToPc(160px);
    }
    .hippoCard {
      width: px2remToPc(171px);
      height: px2remToPc(171px);
      position: absolute;
      left: px2remToPc(14px);
      top: px2remToPc(12px);
      border-radius: px2remToPc(8px);
    }
    .bottom1 {
      width: px2remToPc(200px);
      position: absolute;
      font-family: 'SFCompactDisplayBlod';
      font-style: normal;
      font-weight: 900;
      font-size: px2remToPc(14px);
      text-transform: uppercase;
      color: #623B00;
      bottom: 0;
      z-index: 999;
      text-align: center;
    }
    .number {
      width: 100%;
      position: absolute;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: px2remToPc(26px);
      color: #FECE1E;
      bottom: px2remToPc(56px);
      text-align: center;
    }
    .sol {
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: px2remToPc(26px);
      color: #FFFFFF;
      padding-left: px2remToPc(6px);
    }
    .value {
      width: 100%;
      position: absolute;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: px2remToPc(14px);
      color: #FFFFFF;
      bottom: px2remToPc(38px);
      display: flex;
      justify-content: center;
    }
    .me {
      width: px2remToPc(17px);
      height: px2remToPc(17px);
      float: left;
      margin: px2remToPc(3px) px2remToPc(5px) 0 0;
    }
    .meFive {
      width: px2remToPc(17px);
      height: px2remToPc(17px);
      float: left;
      margin: px2remToPc(3px) px2remToPc(3px) 0 0;
    }
  }
  .card {
    height: px2remToPc(294px);
    width: px2remToPc(200px);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 20px rgba(253, 204, 5, 0.1), inset 0px -4px 20px rgba(254, 203, 70, 0.2), inset 0px 4px 20px rgba(212, 154, 5, 0.2);
    backdrop-filter: blur(px2remToPc(10px));
    border-radius: px2remToPc(10px);
    margin-top: px2remToPc(-80px);
    position: relative;
    .cardImg {
      height: px2remToPc(335px);
      width: px2remToPc(240px);
      position: absolute;
      top: px2remToPc(-21px);
      left: px2remToPc(-19px);
    }
    .cardImgs {
      height: px2remToPc(294px);
      width: px2remToPc(200px);
      position: absolute;
      top: px2remToPc(-1px);
      border: 1px solid #DEC501;
      border-radius: px2remToPc(10px);
      img {
        width: px2remToPc(239px);
        height: px2remToPc(334px);
        margin-left: px2remToPc(-21px);
        margin-top: px2remToPc(-20px);
      }
    }
    .hippoCard {
      width: px2remToPc(171px);
      height: px2remToPc(171px);
      position: absolute;
      left: px2remToPc(14px);
      top: px2remToPc(12px);
      border-radius: px2remToPc(8px);
    }
    .number {
      width: 100%;
      position: absolute;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: px2remToPc(26px);
      color: #FECE1E;
      bottom: px2remToPc(56px);
      text-align: center;
    }
    .sol {
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: px2remToPc(26px);
      color: #FFFFFF;
      padding-left: px2remToPc(6px);
    }
    .value {
      width: 100%;
      position: absolute;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: px2remToPc(14px);
      color: #FFFFFF;
      bottom: px2remToPc(38px);
      display: flex;
      justify-content: center;
    }
    .me {
      width: px2remToPc(17px);
      height: px2remToPc(17px);
      float: left;
      margin: px2remToPc(3px) px2remToPc(5px) 0 0;
    }
    .meFive {
      width: px2remToPc(17px);
      height: px2remToPc(17px);
      float: left;
      margin: px2remToPc(3px) px2remToPc(3px) 0 0;
    }
    .bottom1 {
      width: px2remToPc(200px);
      position: absolute;
      font-family: 'SFCompactDisplayBlod';
      font-style: normal;
      font-weight: 900;
      font-size: px2remToPc(14px);
      text-transform: uppercase;
      color: #623B00;
      bottom: 0;
      z-index: 999;
      text-align: center;
    }
  }
  .drawNumber {
    height: px2remToPc(30px);
    width: px2remToPc(510px);
    line-height: px2remToPc(30px);
    text-align: center;
    font-family: 'SFCompactDisplayLight';
    font-style: normal;
    display: flex;
    justify-content: space-between;
    font-size: px2remToPc(16px);
    margin: 0 auto;
    margin-top: px2remToPc(-33px);
    color: #FFF9E8;
    position: relative;
    z-index: 10;
    padding: 0 px2remToPc(20px);
    .first {
      width: px2remToPc(300px);
      display: flex;
      justify-content: space-between;
      .total {
        width: px2remToPc(100px);
        height: 100%;
        .refresh {
          margin-left: px2remToPc(12px);
          cursor: pointer;
        }
      }
    }
    .price {
      width: px2remToPc(340px);
      text-align: right;
      padding-right: px2remToPc(10px);
    }
    .middle {
      cursor: pointer;
      .right {
        font-size: px2remToPc(12px);
      }
    }
  }
}