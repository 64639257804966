@import "../../px2rem.scss";

.statr-mint2-container{
    background-image: url('../../assets/images/h5/statrMintbg.svg');
    height:px2rem(94px);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    .shoubing{
        position: absolute;
        left:px2rem(36px);
        bottom: px2rem(26px);
        width: px2rem(62px);
        height: px2rem(92px);
    }
    .mintBtns{
        width:px2rem(230px);
        height:px2rem(66px);
        background-image: url('../../assets/images/h5/btnsBg.svg'); 
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right:px2rem(14px);
        bottom: px2rem(22px);
        padding:px2rem(6px) px2rem(27px) 0px px2rem(29px);
        display: flex;
        justify-content: space-between;
        >img{
            width:px2rem(74px);
            height:px2rem(48px);
        }
    }
}