@import '../../../px2rem.scss';

.christmasMint {
  position: relative;
  margin: 0 auto;
  margin-top: 60px;
  height: px2remToPc(700px);
  background: url('../../../assets/images/home/christmas-bg.png') no-repeat;
  background-size: px2remToPc(1192px) px2remToPc(700px);
  background-position-x: 50%;
  transform: translateY(px2remToPc(-100px));
  padding: 0 px2remToPc(100px);
  .inner {
    position: absolute;
    width: px2remToPc(540px);
    height: px2remToPc(600px);
    left: 50%;
    transform: translate(-50%);
    padding-top: px2remToPc(96px);
    .snowLeft {
      width: 244px;
      height: 434px;
      background: url('../../../assets/images/home/snow1.png');
      background-size: 244px 434px;
      position: absolute;
      left: -490px;
      top: -20px;
      .snowImg1 {
        width: 61px;
        height: 60px;
        margin: 30px 0 0 112px;
      }
      .snowImg2 {
        height: 75px;
        width: 74px;
        margin: 175px 0 0 174px;
      }
      .snowImg3 {
        height: 40px;
        width: 41px;
        margin: 200px 0 0 140px;
      }
    }

    .snowRight {
      width: 244px;
      height: 434px;
      background: url('../../../assets/images/home/snow2.png');
      background-size: 244px 434px;
      position: absolute;
      right: -440px;
      top: -60px;
      .snowImg1 {
        width: 45px;
        height: 45px;
        margin: -80px 0 0 -150px;
      }
      .snowImg2 {
        width: 60px;
        height: 60px;
        margin: 100px 0 0 90px;
      }
      .snowImg3 {
        width: 70px;
        height: 70px;
        margin: 380px 0 0 0px;
        float: left;
      }
    }
  }
  .scollTop {
    width: px2remToPc(472px);
    height: px2remToPc(40px);
    line-height: px2remToPc(40px);
    border-radius: 6px;
    background: rgba(20, 16, 30, 0.6);
    margin: 0 auto;
    margin-top: px2remToPc(23px);
    .swiper-container {
      width: px2remToPc(472px);
      height: px2remToPc(40px);
      .swiper-slide {
        width: px2remToPc(472px) !important;
        height: px2remToPc(40px) !important;
        .baby {
          width: px2remToPc(160px);
          height: px2remToPc(40px);
          padding-left: px2remToPc(20px);
          font-family: 'SFCompactDisplayLight';
          color: #FFF9E8;
          font-style: normal;
          font-weight: 400;
          float: left;
          overflow: hidden;
          .beele {
            font-size: px2remToPc(16px);
            color: #FFF9E8;
          }
        }
        .successful {
          font-style: normal;
          font-weight: 400;
          font-size: px2remToPc(12px);
          font-family: 'SFCompactDisplayLight';
          color: #FFF9E8;
          padding-right: px2remToPc(10px);
          .sol {
            color: #FECE1E;
          }
        }
        .prize {
          width: px2remToPc(150px);
          height: 100%;
          padding-right: px2remToPc(10px);
          float: right;
          img {
            height: px2remToPc(30px);
            width: px2remToPc(30px);
            float: left;
            margin-right: px2remToPc(8px);
            margin-top: px2remToPc(5px);
          }
          .ellipsis {
            color: #FFF9E8;
            font-family: 'SFCompactDisplayLight';
            font-style: normal;
            font-weight: 400;
            font-size: px2remToPc(22px);
            width: px2remToPc(24px);
            height: 100%;
            float: left;
            margin-top: px2remToPc(-6px);
          }
        }
      }
    }
  }
  .drawNumber {
    height: px2remToPc(30px);
    width: px2remToPc(510px);
    line-height: px2remToPc(30px);
    text-align: center;
    font-family: 'SFCompactDisplayLight';
    font-style: normal;
    display: flex;
    justify-content: space-between;
    font-size: px2remToPc(16px);
    margin: 0 auto;
    margin-top: px2remToPc(-33px);
    color: #FFF9E8;
    position: relative;
    z-index: 10;
    padding: 0 px2remToPc(20px);
    .first {
      width: px2remToPc(300px);
      display: flex;
      justify-content: space-between;
      .total {
        width: px2remToPc(150px);
        height: 100%;
        text-align: left;
        .refresh {
          margin-left: px2remToPc(12px);
          cursor: pointer;
        }
      }
    }
    .price {
      width: px2remToPc(340px);
      text-align: right;
      padding-right: px2remToPc(10px);
    }
    .middle {
      cursor: pointer;
      .right {
        font-size: px2remToPc(12px);
      }
    }
  }
  .enter {
    width: px2remToPc(120px);
    height: px2remToPc(110px);
    position: absolute;
    right: px2remToPc(-320px);
    top: px2remToPc(20px);
    margin-top: px2remToPc(40px);
    cursor: pointer;
  }
  .snowBtns {
    width: px2remToPc(287px);
    height: px2remToPc(62px);
    margin: 0 auto;
    margin-top: px2remToPc(38px);
    // margin: px2remToPc(38px) 0 px2remToPc(16px) 0;
    cursor: pointer;
    position: relative;
    z-index: 10;
    img {
      width: px2remToPc(287px);
      height: px2remToPc(62px);
    }
  }
  .history {
    width: px2remToPc(90px);
    height: px2remToPc(80px);
    position: absolute;
    right: px2remToPc(20px);
    margin-top: px2remToPc(-70px);
    cursor: pointer;
    img {
      width: px2remToPc(90px);
      height: px2remToPc(80px);
    }
  }
  .mintPrice {
    height: px2remToPc(50px);
    line-height: px2remToPc(50px);
    font-family: 'SFCompactDisplay';
    font-style: normal;
    font-weight: 600;
    font-size: px2remToPc(30px);
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    padding-top: px2remToPc(10px);
    span {
      font-weight: 600;
      font-size: px2remToPc(20px);
    }
  }
  .footer {
    position: absolute;
    bottom: px2remToPc(-93px);
    left: 50%;
    transform: translate(-50%);
    margin: 0 auto;
  }
}