@import '../../px2rem.scss';

.deng5 {
    height: px2remToPc(336px);
    width: px2remToPc(520px);
    background: url("../../assets/images/home/deng3.png") no-repeat;
    background-size: px2remToPc(520px) px2remToPc(336px);
    margin: px2remToPc(-17px) 0 0 px2remToPc(-16px);
}
.deng6 {
    height: px2remToPc(336px);
    width: px2remToPc(520px);
    background: url("../../assets/images/home/deng4.png") no-repeat;
    background-size: px2remToPc(520px) px2remToPc(336px);
    margin: px2remToPc(-17px) 0 0 px2remToPc(-16px);
}
