@import '../../../px2rem.scss';

.pcFooter {
  max-width: px2remToPc(1440px);
  height: 93px;
  display: flex;
  justify-content: center;
  background: rgb(18, 14, 29);
  z-index: 10;
  padding: 0 px2remToPc(120px);
  .footerInner {
    height: 93px;
    width: px2remToPc(1440px);
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    .footerSoga {
      height: 93px;
      width: px2remToPc(224px);
      font-size: 13px;
      line-height: 93px;
      color: #B1B1B5;
      display: flex;
      justify-content: space-between;
    }
    .footerIcon {
      width: 100px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      img {
        width: 32px;
        height: 32px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
}