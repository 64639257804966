.tipsDialog {
  .ant-modal {
    width: 352px !important;
    height: 233px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FECE1E;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
    position: relative;
    top: 150px;
  }
  .ant-modal-content {
    width: 352px;
    height: 233px;
    background: none;
    position: relative;
  }
  .ant-modal-body {
    width: 352px;
    height: 233px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #FECE1E;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .tipsContent {
    height: 100%;
    width: 100%;
    .dialogHeader {
      font-family: 'SFCompactDisplay';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      text-transform: uppercase;
      color: #FFF9E8;
      text-align: center;
      padding: 0 0 20px 0;
    }
    .remark {
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: #FFF9E8;
    }
    .pay {
      width: 180px;
      height: 50px;
      background: linear-gradient(269.93deg, #FDCC05 0.93%, #FEEF67 127.01%);
      box-shadow: 0px 4px 20px rgba(254, 206, 9, 0.3);
      border-radius: 40.704px;
      font-family: 'SFCompactDisplayBlod';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 50px;
      text-align: center;
      color: #623B00;
      margin: 0 auto;
      margin: 30px 0  0 64px;
      cursor: pointer;
    }
    .kefu {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: #3D6BF5;
      cursor: pointer;
      margin: 40px auto;
      .img4 {
        width: 32px;
        height: 28px;
        margin-top: 10px;
        margin-left: 9px;
      }
      // height: 50px;
      // width: 50px;
      // border-radius: 50%;
      // background: #3D6BF5;
      // cursor: pointer;
    }
  }
}