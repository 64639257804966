@import "../../../px2rem.scss";
.congratulationsContainer {
    // background-color: #37364d;
    .topCard {
        width: 100%;
        height: px2rem(92px);
        background-image: url("../../../assets/images/h5/collection.png");
        background-repeat: repeat;
        background-size: 100%;
        position: relative;
        .topPanel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: px2rem(13px) px2rem(18px);
        }
    }
    .titleCard {
        position: relative;
        width: 100%;
        height: fit-content;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0) 81.9%);
        border-radius: px2rem(20px);
        border-top: 1px solid #fece1e;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .text {
            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 600;
            font-size: px2rem(14px);
            /* identical to box height, or 143% */
            text-align: center;
            color: #fece1e;
            padding-top: px2rem(24px);
        }

        .title {
            position: absolute;
            transform: translate(-50%, -55%);
            top: 0px;
            left: 50%;
            height: px2rem(38px);
            width: px2rem(276px);
            background-image: url("../../../assets/images/h5/concetionTitlebg.svg");
            background-repeat: repeat;
            background-size: 100%;

            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 700;
            font-size: px2rem(20px);
            /* or 150% */

            text-transform: uppercase;

            color: #623b00;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .subText {
        margin-top: px2rem(4px);
        text-align: center;
        font-family: "SFCompactDisplayrightLight";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(12px);
        line-height: px2rem(14px);
        text-align: center;
        color: #fece1e;
    }

    .ntfsContainer {
        display: grid;
        grid-template-columns: repeat(2, px2rem(140px));
        column-gap: px2rem(30px);
        row-gap: px2rem(36px);
        justify-content: center;
        margin-top: px2rem(24px);
        padding-bottom: px2rem(24px);
    }
    .noData {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
