 
.rulesContainer1 {
    padding: 20px;
    .rulesContai {
        width: 1170px;
        height: 520px;
        border: 1px solid rgba(255, 249, 232, 0.6);
        border-radius: 20px;
    }
    .most {
        position: absolute;
        bottom: 32px;
        left: 50px;
        color: #fff9e8;
    }
    .one {
        display: flex;
        text-align: center;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16.7%;
            height: 74px;
            border: 1px solid rgba(255, 249, 232, 0.6);
            border-top: none;
            border-left: none;
            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 900;
            color: #fff9e8;
        }
        div:last-child {
            border-right: none;
        }
    }
    .two {
        display: flex;
        text-align: center;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16.7%;
            height: 74px;
            border: 1px solid rgba(255, 249, 232, 0.6);
            border-top: none;
            border-left: none;
            color: #fff9e8;
            font-weight: 100;
        }
        div:last-child {
            border-right: none;
        }
    }
    .last {
        display: flex;
        text-align: center;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16.7%;
            height: 74px;
            border: 1px solid rgba(255, 249, 232, 0.6);
            border-bottom: none;
            border-left: none;
            border-top: none;
            font-weight: 100;
            color: #fff9e8;
        }
        div:last-child {
            border-right: none;
        }
    }
}
