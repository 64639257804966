.support {
  height: 100%;
  width: 100%;
  position: relative;
  .logo {
    width: 164px;
    height: 55px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 200px;
  }
  .remark {
    color: #8B8A8F;
    font-style: normal;
    font-weight: 500;
    font-style: normal;
    font-size: 28px;
    font-family: 'PingFang';
    text-align: center;
    padding-top: 290px;
  }
  .icon {
    height: 33px;
    width: 120px;
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    padding-top: 24px;
    img {
      width: 37px;
      height: 37px;
      cursor: pointer;
    }
  }
}