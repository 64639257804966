.gameDialog {
  .ant-modal {
    width: 836px !important;
    height: 600px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FECE1E;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
  }
  .ant-modal-content {
    width: 836px;
    height: 600px;
    background: none;
  }
  .ant-modal-body {
    width: 836px;
    height: 600px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #FECE1E;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .gameContent {
    height: 100%;
    width: 100%;
    .headerBg {
      height: 65px;
      width: 376px;
      margin: -50px 0 0 210px;
      background: url('../../../assets/images/home/mint_BT.png') no-repeat;
      background-size: 376px 65px;
      float: left;
    }
    .dialogHeader {
      float: left;
      font-family: 'SFCompactDisplayBlod';
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      text-transform: uppercase;
      color: #623B00;
      z-index: 20;
      margin:-40px 0 0 -289px;
    }
    .content {
      width: 730px;
      height: 318px;
      margin: 0 auto;
      position: absolute;
      top: 63px;
      left: 53px;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #FFF9E8;
      p {
        padding-bottom: 24px;
      }
      .four {
        padding-bottom: 24px;
      }
      span {
        color: rgb(253, 79, 5);
        font-weight: 600;
      }
    }
    .games {
      height: 56px;
      width: 788px;
      position: absolute;
      bottom: 135px;
      padding: 0;
      display: flex;
      justify-content: space-between;
      .one {
        width: 56px;
        height: 56px;
        border: 2px solid #FFF9E8;
        border-radius: 10px;
        img {
          height: 100%;
          width: 100%;
          cursor: pointer;
        }
      }
    }
    .footer {
      height: 50px;
      width: 100%;
      position: absolute;
      bottom: 26px;
      display: flex;
      justify-content: center;
      .imgDiv {
        width: 200px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        div {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          background: #3D6BF5;
          cursor: pointer;
          img {
            margin: 0 auto;
          }
        }
        div:hover {
          background-image: linear-gradient(to right, #26DEEA, #BA76FF , #FECE1E)
        }
        .img1 {
          width: 30px;
          height: 30px;
          margin: 0 auto;
          margin-top: 10px;
          margin-left: 10px;
        }
        .img3 {
          width: 24px;
          height: 24px;
          margin-top: 14px;
          margin-left: 14px;
        }
        .img4 {
          width: 32px;
          height: 28px;
          margin-top: 10px;
          margin-left: 9px;
        }
      }
    }
  }
}