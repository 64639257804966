@import "../../../px2rem.scss";
.hippoListContainer {
    .title {
        font-family: "WhiteNTBold";
        font-style: normal;
        font-weight: 700;
        font-size: px2rem(20px);
        /* identical to box height, or 140% */
        margin-top: px2rem(16px);
        margin-bottom: px2rem(16px);
        text-align: left;
        // padding-left: px2rem(17px);

        color: #fece1e; 
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 0.16rem;
            width: 1.48rem;
        }
        .road{
            margin:0  px2rem(16px);
        }
    }

    .goTopBtn {
        width: px2rem(40px);
        height: px2rem(40px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border-radius: 50%;
        position: fixed;
        bottom: px2rem(100px);
        right: px2rem(20px);
        img{
            width: px2rem(15px);
            height: px2rem(17px);
        }
    }
}
