@import "../../px2rem.scss";

.top_win_itemItem {
    padding-left: 0;
    background-color: #37364d;
    .adm-list-item-content {
        border-top-width: 0;
        padding-right: 0;
        background-color: #37364d;
    }
    .adm-list-item-content-main {
        padding: 0;
    }
}
.top_win_itemRow {
    padding: 14px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .col1{
        height:30px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .col2 {
        .top {
            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 14px;

            color: #fff9e8;
            img {
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }
        }
        .bottom {
            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            color: #cccccc;
        }
    }
    .col3 {
        img {
            width: 30px;
            height: 30px;
            margin-right: 8px;
        }
    }
    .col4 {
        font-family: "SFCompactDisplayBlod";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        /* or 250% */

        color: #fff9e8;
    }
}
