@import '../../../px2rem.scss';

.cardContentNew {
  position: relative;
  height: px2remToPc(551px);
  width: px2remToPc(1440px);
  padding: 0 70px;
  background: url("../../../assets/images/home/bg_soga_new.png") no-repeat;
  background-size: px2remToPc(1440px) px2remToPc(551px);
  background-position-x: 50%;
  transform: translateY(px2remToPc(-100px));
  margin: 0 auto;
  .gold1 {
    width: px2remToPc(170px);
    height: px2remToPc(206px);
    background: url("../../../assets/images/home/jb1.png") no-repeat;
    background-size: px2remToPc(170px) px2remToPc(206px);
    position: absolute;
    top: px2remToPc(-60px);
    right: 0;
  }
  .gold2 {
    width: px2remToPc(178px);
    height: px2remToPc(222px);
    background: url("../../../assets/images/home/jb1.png") no-repeat; 
    background-size: px2remToPc(178px), px2remToPc(222px);
    position: absolute;
    top: px2remToPc(-60px);
    left: px2remToPc(60px);
  }
  .gold3 {
    width: px2remToPc(170px);
    height: px2remToPc(206px);
    background: url("../../../assets/images/home/jb1.png") no-repeat;
    background-size: px2remToPc(170px) px2remToPc(206px);
    position: absolute;
    top: px2remToPc(300px);
    left: px2remToPc(20px);
  }
  .scollTop {
    background: rgba(20, 16, 30, 0.3);
    border-radius: px2remToPc(6px);
    position: absolute;
    top: px2remToPc(187px);
    left: 50%;
    transform: translateX(-50%);
    height: px2remToPc(48px);
    width: px2remToPc(476px);
    // margin-left: -40px;
    z-index: 1;
    .activity {
      height: px2remToPc(50px);
      width: px2remToPc(50px);
      border-radius: px2remToPc(6px);
      float: left;
      margin-right: px2remToPc(24px);
      .entrance {
        height: px2remToPc(45px);
        width: px2remToPc(70px);
        cursor: pointer;
      }
    }
    .swiper-container {
      width: px2remToPc(480px);
      height: px2remToPc(48px);
      .swiper-slide {
        width: px2remToPc(480px) !important;
        height: px2remToPc(48px) !important;
        .baby {
          width: px2remToPc(160px);
          height: px2remToPc(48px);
          padding-left: px2remToPc(20px);
          line-height: px2remToPc(48px);
          font-family: "SFCompactDisplayLight";
          color: #fff9e8;
          font-style: normal;
          font-weight: 400;
          float: left;
          overflow: hidden;
          .beele {
            font-size: px2remToPc(16px);
            color: #fff9e8;
          }
        }
        .successful {
          font-style: normal;
          font-weight: 400;
          font-size: px2remToPc(12px);
          font-family: "SFCompactDisplayLight";
          color: #fff9e8;
          line-height: px2remToPc(48px);
          padding-right: px2remToPc(10px);
          .sol {
            color: #fece1e;
          }
        }
        .prize {
          width: px2remToPc(150px);
          height: 100%;
          padding-right: px2remToPc(10px);
          float: right;
          img {
            height: px2remToPc(30px);
            width: px2remToPc(30px);
            float: left;
            margin-right: px2remToPc(8px);
            margin-top: px2remToPc(8px);
          }
          .ellipsis {
            color: #fff9e8;
            font-family: "SFCompactDisplayLight";
            font-style: normal;
            font-weight: 400;
            font-size: px2remToPc(22px);
            width: px2remToPc(24px);
            height: 100%;
            float: left;
          }
        }
      }
    }
  }
   
  .luckyMintDiv {
    position: absolute;
    top: px2remToPc(302px);
    right: px2remToPc(147px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    >img{
      cursor: pointer;
      width: px2remToPc(192px);
      height: px2remToPc(71px);
    }
    > div {
      margin-top:px2remToPc(4px);
      color:#FFFFFF;
      font-size: px2remToPc(12px);
      font-family: "SF Compact Display";
      label {
        font-style: normal;
        font-weight: 600;
        font-size: px2remToPc(18px);
      }
    }

  }
  .bntsDiv{
    position: absolute;
    top: px2remToPc(472px);
    right: px2remToPc(146px);
    display: flex;
    align-items: center;
    justify-content:space-between;
    width: px2remToPc(190px);
    >img{
      cursor: pointer;
    }
    .pcHistory{
      width: px2remToPc(107px);
      height: px2remToPc(41px);
    }
    .pcRules{
      width: px2remToPc(48px);
      height: px2remToPc(48px);
    }
  }
  .slider {
    width: px2remToPc(220px);
    height: px2remToPc(45px);
    position: absolute;
    transform: translateX(-50%);
    top: px2remToPc(267px);
    right: px2remToPc(25px);
    .blcok {
      width: 100%;
      height: 100%;
      float: left;
      .ant-slider-rail {
        height: px2remToPc(8px);
        background: rgba(255, 255, 255, 0.3);
        box-shadow: inset -2px 0px 1px rgba(198, 183, 217, 0.25), inset 2px 0px 1px rgba(198, 183, 217, 0.6),
          inset 0px 2px 1px rgba(204, 204, 204, 0.9), inset 0px -2px 1px rgba(198, 183, 217, 0.6);
        border-radius: px2remToPc(20px);
      }
      .ant-slider-track {
        height:px2remToPc(8px);
        background: linear-gradient(140.3deg, #fff1c1 2.92%, #ffeea8 46.48%);
        box-shadow: 0px 0px 20px rgba(253, 204, 5, 0.3);
        border-radius: px2remToPc(15px);
      }
      .ant-slider-dot {
        height: px2remToPc(15px);
        width: 2px;
        background: white;
        border: 1px solid white;
        position: absolute;
        top: px2remToPc(-14px);
      }
      .ant-slider-handle {
        background: none;
        width: px2remToPc(20px);
        height: px2remToPc(20px);
        background: url("../../../assets/images/home/slider.png") no-repeat;
        background-size: 100%;
        position: absolute;
        top: -1px;
        border: none !important;
      }
      .ant-slider-handle:focus {
        border-color: none !important;
        box-shadow: none !important;
      }
      .ant-slider-handle::after {
        display: none;
      }
      .ant-slider-dot-active {
        border: none;
      }
      .ant-slider-mark-text {
        margin-top: 0;
        position: absolute;
        top: px2remToPc(-45px);
        font-style: normal;
        font-weight: 600;
        font-size: px2remToPc(14px);
        color: #fff9e8;
        font-family: "SFCompactDisplayLight";
      }
      .el-slider__button {
        display: none;
      }
    }
    .solMint {
      font-family: "SFCompactDisplayBlod";
      font-style: normal;
      font-weight: 900;
      font-size: px2remToPc(24px);
      text-transform: uppercase;
      color: #ffffff;
      width: px2remToPc(120px);
      float: left;
      margin-top: px2remToPc(70px);
      text-align: right;
      margin-left: px2remToPc(80px);
      div {
        text-align: right;
        font-size: px2remToPc(20px);
      }
      span {
        font-size: px2remToPc(16px);
      }
    }
    .btns {
      width: px2remToPc(260px);
      height: px2remToPc(100px);
      background: url("../../../assets/images/home/btn@2x.png") no-repeat;
      background-size: 100%;
      z-index: 22;
      position: relative;
      float: right;
      margin-top: px2remToPc(60px);
      margin-right: px2remToPc(-20px);
      cursor: pointer;
    }
    .btns1 {
      width: px2remToPc(260px);
      height: 100px;
      background: url("../../../assets/images/home/mint_dianji@2x.png") no-repeat;
      background-size: 100%;
      z-index: 22;
      position: relative;
      float: right;
      margin-top: px2remToPc(60px);
      margin-right: px2remToPc(-20px);
    }
    .guide {
      height: px2remToPc(80px);
      width: px2remToPc(80px);
      position: absolute;
      bottom: px2remToPc(130px);
      right: px2remToPc(74px);
      z-index: 999;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .drawContent{
    height: px2remToPc(223px);
    width: px2remToPc(892px);
    // background-color: red;
    position: absolute;
    top: px2remToPc(269px);
    left: px2remToPc(161px);
    border-radius: px2remToPc(10px);
    padding:px2remToPc(10px) px2remToPc(5px) 0px px2remToPc(5px);
    .cards{
      display: flex;
      justify-content: space-around;
      .cardActive {
        width: px2remToPc(130px);
        height: px2remToPc(192px);
        position: relative;
        background: rgba(253, 204, 5, 0.3);
        border-radius: px2remToPc(10px);
      }
      .cardItem{
        width: px2remToPc(130px);
        height: px2remToPc(192px);
        position: relative;
        background: rgba(255, 255, 255, 0.1);
        border-radius: px2remToPc(10px);
      }
      .cardImgs {
        img {
          width: px2remToPc(130px);
          height: px2remToPc(192px);
        }
      }
      .cardImg {
        width: px2remToPc(130px);
        height: px2remToPc(192px);
      }
      .hippoCard {
        position: absolute;
        width: px2remToPc(110px);
        height: px2remToPc(110px);
        border-radius: px2remToPc(6px);
        top: px2remToPc(8px);
        left: px2remToPc(9px);
      }
      .number {
        text-align: center;
        color: #FDCC05;
        font-weight: 700;
        font-size: px2remToPc(14px);
        font-family: 'SFCompactDisplayLight';
        transform: translateY(px2remToPc(-60px));
        .sol {
          color: white;
          padding-left: px2remToPc(4px);
        }
      }
      .value {
        text-align: center;
        color: white;
        font-weight: 400;
        font-family: 'SFCompactDisplayLight';
        font-size: px2remToPc(12px);
        transform: translateY(px2remToPc(-60px));
        .meFive {
          width: px2remToPc(12px);
          height: px2remToPc(12px);
          margin: px2remToPc(-2px) px2remToPc(3px) 0 0;
        }
        .me {
          width: px2remToPc(12px);
          height: px2remToPc(12px);
          margin: px2remToPc(-2px) px2remToPc(3px) 0 0;
        }
      }
    }
    .drawNumber {
      color: #FFF9E8;;
      font-size: px2remToPc(14px);
      font-family: 'SFCompactDisplayLight';
      padding-left: px2remToPc(14px);
      .refresh {
        margin-left: px2remToPc(6px);
      }
    }
  }
}
