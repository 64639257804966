.hippoList {
  height: calc(100vh - 70px);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background: rgb(13, 14, 29);
  .hippoInner {
    width: 1440px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 122px;
    .header {
      color: #FECE1E;
      font-family: 'WhiteNTBold';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      padding: 30px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 20px;
        width: 180px;
      }
      .partnerLeft {
        margin-right: 30px;
      }
      .partnerRight {
        margin-left: 20px;
      }
    }
    .no {
      width: 100%;
      height: 496px;
      text-align: center;
      line-height: 496px;
      color: white;
      font-size: 24px;
    }
    .content {
      width: 100%;
      display:grid;
      justify-items: center;
      align-items: center;
      grid-template: repeat(2,233px) / repeat(4, 282px);
      gap: 30px 20px;
      .edition {
        width: 282px;
        height: 233px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
        box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
        backdrop-filter: blur(45px);
        border-radius: 10px;
        cursor: pointer;
        .hippoImg {
          width: 282px;
          height: 158px;
          border-radius: 10px 10px 0 0;
        }
        .hippoBottom {
          padding-left: 12px;
          .logo {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background: white;
            margin: -16px 0 4px 0;
            z-index: 10;
            position: relative;
            border: 1px solid #FECE1E;
            overflow: hidden;
            img {
              width: 38px;
              height: 38px;
              border-radius: 50%;
            }
          }
          .bala {
            color: #FFF9E8;
            font-family: 'SFCompactDisplayLight';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
          .detail {
            color: rgba(255, 249, 232, 0.6);
            font-family: 'SFCompactDisplayLight';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
          }
          .of {
            // width: 66px;
            // height: 26px;
            line-height: 26px;
            text-align: center;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 6px;
            color: #FFFEFA;
            font-weight: 700;
            font-family: 'SFCompactDisplayLight';
            position: absolute;
            top: 10px;
            left: 9px;
            padding:0 10px;
          }
          .end {
            width: 54px;
            height: 56px;
            position: absolute;
            top: -6px;
            right: -7px;
          }
        }
      }
    }
    .pagination {
      height: 70px;
      width: 100%;
      bottom: 110px;
      text-align: center;
      margin: 0 auto;
      margin:40px 0 30px 0;
      .ant-pagination-prev,
      .ant-pagination-next,
      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        height: 24px;
      }
      .nextBtn {
        width: 96px;
        height: 30px;
        left: 556px;
        top: 683px;
        border: 1px solid #fff9e8;
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #fff9e8;
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        margin-top: -3px;
      }
      .ant-pagination-item:hover a {
        color: #fdcc05 !important;
        line-height: 30px;
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
      .ant-pagination-item {
        border-radius: 8px;
        border: 1px solid #fff9e8;
        border-radius: 8px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background: none;
        color: #fff9e8;
        a {
          color: #fff9e8;
          line-height: 30px;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
      }
      .ant-pagination-item-active {
        border-radius: 8px;
        backdrop-filter: blur(2px);
        box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
        border: 1px solid #fdcc05;
        background: rgba(255, 255, 255, 0.5);
        width: 30px;
        height: 30px;
        text-align: center;
        a {
          color: #fdcc05;
          line-height: 30px;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  height: 20px;
  width: 4px;
}
::-webkit-scrollbar-thumb:vertical {
  background: rgba(255, 249, 232, 0.3);
  border-radius: 8px;
}

@media (max-width:1300px) {
  .hippoList {
    // height: calc(100vh - 70px);
    // width: 1300px;
    // position: fixed;
    // left: 50%;
    // bottom: 0;
    // transform: translateX(-50%);
    // padding: 0 122px;
  }
}