// @normal: #3F66E0;
// @edit: #00C7B8;
// @rest: #FBA908;
// @delete: #EC2424;
// @other: #09BCE4; 
// @active: #00f6f0;
// 可视化组件标题高度
// @titleHeight: 25px;
@import '~antd/dist/antd.css'; 

:root {
  --color-normal: #3F66E0;
  --color-edit: #00C7B8;
  --color-rest: #FBA908;
  --color-delete: #EC2424;
  --color-other: #09BCE4;
  --color-active: #00f6f0;
  --titleHeight: 25px;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'SFCompactDisplayBlod', 'SFCompactDisplay',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: rgb(18, 14, 29);
  /* 公共样式 */
  /* 按钮 */
  /* 新增 导入 导出 */
  .n-btn {
    color: #fff;
    background-color: var(--color-norma);
  }
  /* 编辑 */
  .e-btn {
    color: #fff;
    background-color:var(--color-edit);
  }
  /* 重置 */
  .w-btn {
    color: #fff;
    background-color: var(--color-rest);
  }
  /* 删除 */
  .d-btn {
    color: #fff;
    background-color: var(--color-delete);
  }
  /* 其他 */
  .o-btn {
    color: #fff;
    background-color: var(--color-other);
  }
}
.connect-modal-gourp-list,.particle-connect-modal-gourp-list{
  >div:last-child{
    display: none;
  }
}


@font-face {
  font-family: "SFCompactDisplay"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/SFCompactDisplay-Semibold_0.otf") format("truetype");
}

@font-face {
  font-family: "SFCompactDisplayBlod"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/sf-compact-display-bold_0.otf") format("truetype");
}

@font-face {
  font-family: "SFCompactDisplayLight"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/SFCompactDisplay-Light_0.otf") format("truetype");
}

@font-face {
  font-family: "WhiteNTBold"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/White-NT-Bold.otf") format("truetype");
}