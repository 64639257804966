.rulesDialog {
  .ant-modal {
    width: 1236px !important;
    height: 620px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FECE1E;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
  }
  .ant-modal-content {
    width: 1236px;
    height: 620px;
    background: none;
    background: url('../../../assets/images/home/rules.png') no-repeat;
    background-size: 1234px 620px;
    position: relative;
  }
  .ant-modal-body {
    width: 1236px;
    height: 620px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #FECE1E;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .rulesContent {
    width: 100%;
    height: 100%;
  }
  .rulesHeader {
    position: absolute;
    top: -20px;
    left: 380px;
    height: 65px;
    width: 468px;
    background: url('../../../assets/images/home/bg_congratulations@2x.png') no-repeat;
    background-size: 100%;
    font-family: 'SFCompactDisplayBlod';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    text-transform: uppercase;
    color: #623B00;
    text-align: center;
    padding-top: 8px;
    z-index: 2;
  }
  .rulesContai {
    width: 1170px;
    height: 520px;
    border: 1px solid rgba(255, 249, 232, 0.6);
    border-radius: 20px;
    position: absolute;
    top: 40px;
    left: 30px;
  }
  .most {
    position: absolute;
    bottom: 32px;
    left: 50px;
    color: #FFF9E8;
  }
  .one {
    display: flex;
    text-align: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16.7%;
      height: 74px;
      border: 1px solid rgba(255, 249, 232, 0.6);
      border-top: none;
      border-left: none;
      font-family: 'SFCompactDisplayBlod';
      font-style: normal;
      font-weight: 900;
      color: #FFF9E8;
    }
    div:last-child {
      border-right: none;
    }
  }
  .two {
    display: flex;
    text-align: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16.7%;
      height: 74px;
      border: 1px solid rgba(255, 249, 232, 0.6);
      border-top: none;
      border-left: none;
      color: #FFF9E8;
      font-weight: 100;
    }
    div:last-child {
      border-right: none;
    }
  }
  .last {
    display: flex;
    text-align: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16.7%;
      height: 74px;
      border: 1px solid rgba(255, 249, 232, 0.6);
      border-bottom: none;
      border-left: none;
      border-top: none;
      font-weight: 100;
      color: #FFF9E8;
    }
    div:last-child {
      border-right: none;
    }
  }
}
  
