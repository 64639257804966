@import "../../px2rem.scss";

.h5HippoListLayout {
    display: grid;
    grid-template-columns: repeat(2, px2rem(172px));
    column-gap: px2rem(12px);
    row-gap: px2rem(16px);
    justify-content: center;
    margin-bottom: px2rem(36px);
    .h5HippoListLayoutItem {
        width: px2rem(172px);
        height: px2rem(184px);
        border-radius: 10px;

        .top {
            width: 100%;
            .bg-img {
                width: 100%;
                height: px2rem(94px);
                border-top-left-radius: px2rem(10px);
                border-top-right-radius: px2rem(10px);
            }

            position: relative;

            .end {
                position: absolute;
                right: px2rem(-5px);
                top: px2rem(-3px);
                width: px2rem(36px);
                height: px2rem(35px);
            }

            .logo {
                position: absolute;
                width: px2rem(22px);
                height: px2rem(22px);
                left: px2rem(8px);
                bottom: px2rem(-11px);
                z-index: 100;
                border-radius: 50%;
            }
            .name {
                background: rgba(0, 0, 0, 0.7);
                border-radius: px2rem(4px);
                position: absolute;
                font-family: "SF Compact Display";
                font-style: normal;
                font-weight: 700;
                font-size: px2rem(12px);
                text-align: center;

                color: #fffefa;
                padding: px2rem(4px);
                top: px2rem(8px);
                left: px2rem(8px);
            }
        }
        .btm {
            height: px2rem(91px);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
            box-shadow: inset 0px -4px 60px rgb(166 139 197 / 40%), inset 0px 4px 20px rgb(166 139 197 / 40%);
            -webkit-backdrop-filter: blur(45px);
            backdrop-filter: blur(45px);
            /* transform: matrix(1, 0, 0, -1, 0, 0); */
            border-bottom-left-radius: px2rem(10px);
            border-bottom-right-radius: px2rem(10px);
            padding: px2rem(10px) px2rem(8px);
            .text {
                font-style: normal;
                font-weight: 500;
                font-size: px2rem(12px);
                line-height: px2rem(14px);
                /* or 117% */
                color: #fff9e8;
                min-height: px2rem(30px);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                margin-top: px2rem(8px);
            }
            .subtext {
                font-style: normal;
                font-weight: 400;
                font-size: px2rem(12px);
                line-height: px2rem(12px);
                /* or 120% */
                margin-top: px2rem(4px);
                color: rgba(255, 249, 232, 0.6);
            }
            .last {
                font-style: normal;
                font-weight: 400;
                font-size: px2rem(12px);
                line-height: px2rem(12px);
                /* or 120% */
                margin-top: px2rem(4px);
                color: rgba(255, 249, 232, 0.6);
            }
        }
    }
}

.viewBtns {
    width: 100%;
    padding: 0px px2rem(10px);
    margin-top: px2rem(30px);
    margin-bottom: px2rem(36px);
    > div {
        height: px2rem(40px);
        background: #37364d;
        border-radius:  px2rem(20px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "SF Compact Display";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(14px); 
        color: #fff9e8;
    }
}
