.sponsor {
  width: 1156px;
  height: 560px;
  .roadAsid {
    height: 50px;
    width: 900px;
    // margin-top: 100px;
    .roadList {
      height: 50px;
      width: 900px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      // margin-top: -100px;
      // margin-top: 100px;
      .roadmap {
        height: 50px;
        width: 900px;
        text-align: center;
        line-height: 50px;
        .road {
          font-family: 'WhiteNTBold';
          font-style: normal;
          font-weight: 700;
          font-size: 44px;
          text-transform: uppercase;
          background: linear-gradient(270deg, #FDCC05 1.52%, #FED462 87.8%);
          -webkit-text-fill-color: transparent;
          background-clip: text;
          height: 60px;
          width: 600px;
          float: left;
        }
        .partnerLeft {
          height: 20px;
          width: 180px;
          float: left;
          margin-top: 13px;
          margin-right: -30px;
        }
        .partnerRight {
          height: 20px;
          width: 180px;
          float: left;
          margin-top: 13px;
          margin-left: -38px;
        }
      }
    }
  }
  .sponsorList {
    width: 1156px;
    height: 400px;
    position: absolute;
    left: 50%;
    margin-top: 40px;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px -4px 20px rgba(254, 203, 70, 0.2), inset 0px 4px 20px rgba(212, 154, 5, 0.2);
    border-radius: 10px;
    .sponsorOne {
      height: 80px;
      width: 100%;
      display:grid;
      justify-items: center;
      align-items: center;
      grid-template: repeat(1,80px) / repeat(6,192.5px);
      .sponsorImg {
        width: 100%;
        height: 100%;
         display:grid;
        justify-items: center;
        align-items: center;
        border-bottom: 0.5px solid rgb(94, 80, 46);
        border-right: 0.5px solid rgb(94, 80, 46);
      }
      .sponsor1 {
        width: 100%;
        height: 100%;
        display:grid;
        justify-items: center;
        align-items: center;
        border-right: none;
        border-bottom: 0.5px solid rgb(94, 80, 46);
      }
      div {
        .imgOne {
          width: 130px;
          height: 40px;
        }
        .imgTwo {
          width: 148px;
          height: 28px;
        }
        .imgThree {
          width: 170px;
          height: 23px;
        }
        .imgFour {
          width: 106px;
          height: 50px;
          margin-top: -8px;
        }
        .imgFive {
          width: 104px;
          height: 27px;
        }
        .imgSix {
          width: 113px;
          height: 28px;
        }
        .imgSeven {
          width: 135px;
          height: 32px;
        }
        .imgEight {
          width: 154px;
          height: 24px;
        }
        .imgNine {
          width: 135px;
          height: 29px;
        }
        .imgTen {
          width: 133px;
          height: 34px;
        }
        .imgEle {
          width: 132px;
          height: 34px;
        }
        .imgTwe {
          width: 164px;
          height: 42px;
        }
        .imgThi {
          width: 120px;
          height: 26px;
        }
        .imgFourteen {
          width: 132px;
          height: 28px;
        }
        .imgFiveteen {
          width: 112px;
          height: 40px;
        }
        .imgSixteen {
          width: 150px;
          height: 24px;
        }
        .imgSeventeen {
          width: 146px;
          height: 24px;
        }
        .imgEighteen {
          width: 159px;
          height: 40px;
        }
        .imgNines {
          width: 120px;
          height: 34px;
        }
        .imgTwenty {
          width: 86px;
          height: 19px;
        }
        .imgTwenOne {
          width: 150px;
          height: 24px;
        }
        .imgTwenTwo {
          width: 139px;
          height: 28px;
        }
        .imgTwenThree {
          width: 126px;
          height: 46px;
        }
        .imgTwenFour {
          width: 145px;
          height: 42px;
        }
      }
    }
    .sponsorTwo {
      height: 80px;
      width: 100%;
      display:grid;
      justify-items: center;
      align-items: center;
      grid-template: repeat(1,80px) / repeat(6,192.5px);
      // border: 1px solid #fff;
      .sponsorImg {
        width: 100%;
        height: 100%;
        display:grid;
        justify-items: center;
        align-items: center;
        border-right: 0.5px solid rgb(94, 80, 46);
      }
      .sponsor1 {
        width: 100%;
        height: 100%;
        display:grid;
        justify-items: center;
        align-items: center;
        border-right: none;
      }
      div {
        .imgOne {
          width: 170px;
          height: 56px;
        }
        .imgTwo {
          width: 159px;
          height: 39px;
        }
        .imgThree {
          width: 67px;
          height: 48px;
        }
        .imgFour {
          width: 90px;
          height: 40px;
        }
        .imgFive {
          width: 150px;
          height: 20px;
        }
        .imgSix {
          width: 68px;
          height: 32px;
        }
      }
    }
  }
  .footer {
    height: 93px;
    width: 1156px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    margin-top: 430px;
    .footerSoga {
      height: 93px;
      width: 224px;
      font-size: 13px;
      line-height: 93px;
      color: #B1B1B5;
      display: flex;
      justify-content: space-between;
    }
    .footerIcon {
      width: 100px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      img {
        width: 32px;
        height: 32px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
}


