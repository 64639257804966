@import '../../px2rem.scss';

.deng1 {
    height: px2remToPc(420px);
    width: px2remToPc(580px);
    background: url("../../assets/images/home/deng1.png") no-repeat;
    background-size: px2remToPc(580px) px2remToPc(420px);
    position: absolute;
    top: px2remToPc(155px);
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0px;
}
.deng2 {
    height: px2remToPc(420px);
    width: px2remToPc(580px);
    background: url("../../assets/images/home/deng2.png") no-repeat;
    background-size: px2remToPc(580px) px2remToPc(420px);
    position: absolute;
    top: px2remToPc(155px);
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0px;
}
