@import "../../px2rem.scss";

.maskContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: px2rem(299px);
    padding: px2rem(20px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 11.13%, rgba(255, 255, 255, 0.3) 100%);
    border: 1px solid #fece1e;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: px2rem(20px);

    .title {
        margin: auto;
        font-family: "SFCompactDisplayBlod";
        font-style: normal;
        font-weight: 600;
        font-size: px2rem(20px);
        /* identical to box height */
        text-align: center;
        text-transform: uppercase;

        color: #fff9e8;

        // position: relative;
    }
    .closeIcon {
        right: px2rem(5px);
        top: px2rem(5px);
        width: px2rem(30px);
        height: px2rem(30px);
        position: absolute;
    }
    .maskContent {
        margin: auto;
        margin-top: px2rem(20px);
        font-family: "SFCompactDisplayLight";
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(14px);
        text-align: center;
        color: #fff9e8;
        .addFunsContentText {
            font-size: px2rem(16px);
            line-height: px2rem(19px);
            label {
                color: #fece1e;
            }
        }
    }

    .btnPanel {
        margin-top: px2rem(28px);
        display: flex;
        align-items: center;
        justify-content: center;
        .btns {
            width: px2rem(146px);
            height: px2rem(50px);
            background: #fdcc05;
            box-shadow: 0px 4px 20px rgba(254, 206, 9, 0.3);
            border-radius: 40.704px;
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: "SFCompactDisplayBlod";
            font-style: normal;
            font-weight: 600;
            font-size: px2rem(20px);

            color: #623b00;
        }

        .imgbtns {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            img {
                width: px2rem(40px);
                height: px2rem(40px);
            }
        }
    }

    .addressPanel {
        margin-top: px2rem(16px); 
        background: rgba(18, 14, 29, 0.3);
        border-radius: 6px;
        height: px2rem(38px);

        display: flex;
        align-items: center;
        justify-content: center;
 
        font-style: normal;
        font-weight: 400;
        font-size: px2rem(13px); 
        color: #FFFFFF;
    }
}
.funsContainer{
    padding: px2rem(20px)  px2rem(10px);
}
