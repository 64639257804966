.luckListDialog {
  .ant-modal {
    width: 836px !important;
    height: 500px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 11.13%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FECE1E;
    box-shadow: inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4);
    backdrop-filter: blur(45px);
    border-radius: 20px;
  }
  .ant-modal-content {
    width: 836px;
    height: 500px;
    background: none;
  }
  .ant-modal-body {
    width: 836px;
    height: 500px;
    background: none;
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px 0px 1px rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    font-size: 16px;
    color: #FECE1E;
    margin: 6px 6px 0 0;
  }
  .ant-modal-close-icon {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .dialogContent {
    height: 400px;
    width: 100%;
    .luckHeader {
      position: absolute;
      top: -20px;
      left: 230px;
      height: 65px;
      width: 376px;
      background: url('../../../assets/images/home/bg_congratulations@2x.png') no-repeat;
      background-size: 100%;
      font-family: 'SFCompactDisplayBlod';
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      text-transform: uppercase;
      color: #623B00;
      text-align: center;
      padding-top: 2px;
      z-index: 2;
    }
    .tableHeader {
      width: 760px;
      height: 33px;
      line-height: 33px;
      background: rgba(255, 249, 232, 0.1);
      border-radius: 5px;
      margin: 40px 0 0 10px;
      color: #FFF9E8;
      display: flex;
      font-family: 'SFCompactDisplayLight';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      div {
        width: 160px;
        height: 33px;
        text-align: center;
      }
      .mintItem {
        width: 120px;
        height: 33px;
      }
      .constItem {
        width: 120px;
        height: 33px;
      }
      .statusItem {
        width: 180px;
        height: 33px;
      }
      .timeItem {
        width: 200px;
        height: 33px;
      }
    }
    .tableContent {
      width: 790px;
      height: 370px;
      margin-left: 10px;
      overflow-y: auto;
      .loadIcon {
        font-size: 50px;
        height: 50px;
        color: white;
        margin: 150px 0 0 350px;
      }
      .noData {
        padding: 150px 0 0 350px;
        font-size: 20px;
        font-weight: 700;
        font-family: 'SFCompactDisplayLight';
        color: #FFF9E8;
      }
      .tableItem {
        width: 100%;
        height: 30px;
        margin-top: 20px;
        div {
          width: 160px;
          float: left;
          text-align: center;
          font-family: 'SFCompactDisplayLight';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 14px;
          text-align: center;
          color: #FFF9E8;
        }
        .mintCss {
          width: 120px;
          height: 30px;
        }
        .costCss {
          width: 120px;
          height: 30px;
        }
        .viewCss {
          width: 120px;
          color: #FDC605;
          cursor: pointer;
        }
        .statusCss {
          width: 180px;
        }
        .timeCss {
          width: 200px;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    background: 0;
    height: 20px;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb:vertical {
    background: rgba(255, 249, 232, 0.3);
    border-radius: 8px;
  }
}