.h5Header {
    // max-width: 375px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 8px 0 20px;
    color: #fff;
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 20px;
        }
        .logox {
            margin: 0 5px;
        }
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: center;
        .connectBtn {
            height: 28px;
            border-radius: 40;
            border: 1px solid #fece1e;
            border-radius: 40px;
            color: #fece1e;
            font-size: 11px;
            text-align: center;
            padding: 5px;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-right: 10px;
        }
        .wrong{
            border: 1px solid #ff494a;
            background-color: #ff494a;
            color:#fff;
        }
    }

    .h5headerDropDown {
        background-color: transparent;
        height: 50px;
        color: #fff;
        .adm-dropdown-item-title {
            height: 50px;
        }
    }
}
.h5_header_panel {
    background-color: #37364d;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 0 48px;
    .h5_header_panel_Item{
        height: 60px;
        font-family: 'SFCompactDisplayBlod';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;  
        color: #FFF9E8;

        display: flex;
        align-items: center;
        
        justify-content: center;
        border-bottom: 1px solid #8987AB;  
    }
    .h5_header_panel_Item:last-child{
        border-bottom-width: 0px;
    }
}
.adm-popup-body { 
    background-color: transparent; 
    .adm-dropdown-item-content {
        background-color: transparent; 
    } 
}
