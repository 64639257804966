 
@import '../../px2rem.scss';
  

.hippo {
  max-width: 1520px;
  height: 100%;
  background: rgb(18, 14, 29);
  margin: 0 auto;
  .isFalse {
    width: 100%;
    height: 100%;
    .hoverDIV::before {
      content: "";
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px); 
      background: linear-gradient(110deg, #2698EA 50%, #FFB176 , #FECE18);
      border-radius: 28px;
    }
    .hoverDIV {
      position: fixed;
      right: 80px;
      bottom:  calc(50vh - 90px); 
      z-index: 999;
      text-align: center;
      // z-index: 25; 
      border-radius: 28px; 
      height:180px;
      width:58px;
      // padding:1px;
      .contair{
        background: linear-gradient(190.38deg, rgba(74, 69, 118, 0) 7.74%, #2e2955 188.79%), #2f3142;
        border-radius: 28px;
        // padding: 16px 7px;
        position: absolute;
        z-index: 1000;
        width: 58px;
        height: 180px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .kefu {
          height: 44px;
          width: 44px;
          border-radius: 50%;
          background: white;
          margin-top: 8px;
          cursor: pointer;
          img {
            height: 26px;
            // width: 34px;
            margin: 10px 0 0 0;
          }
        }
        .kefu:hover {
          background-image: linear-gradient(to right, #c5fbff, #debdff, #ffd745);
        }
      }
    
    }
  
    
  
   
    li {
      list-style: none;
    }
    .trade {
      height: 100vh;
      width: 100%;
      background: rgb(18, 14, 29);
      position: fixed;
      left: 0;
      top: 70px;
      z-index: 21;
      .tradeContent {
        height: 180px;
        width: 420px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 50%;
        margin-top: -180px;
        img {
          width: 420px;
          height: 131px;
        }
        .remark {
          width: 510px;
          text-align: center;
          color: #ffffff;
          font-weight: 400;
          font-size: 16px;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          padding-top: 21px;
          margin-left: -50px;
          span {
            color: #FDCC05;
            cursor: pointer;
          }
        }
      }
    }
    .hippoContent {
      // height: 1100px;
      // height: px2remToPc(730px);
      height: calc(100vh - px2remToPc(90px));
      position: relative;
      min-height: px2remToPc(708px);
      .footer {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: px2remToPc(-80px);
        bottom: 0;
      }
      .footer1 {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
      
      .hyper {
        height: px2remToPc(70px);
        margin-top: px2remToPc(90px);
        font-size: px2remToPc(44px);
        font-weight: 700;
        text-align: center;
        background: linear-gradient(0deg, #fdcc05 19.79%, #fed467 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-family: "WhiteNTBold";
      }
      .studio {
        height: 26px;
        margin-top: 27px;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #fff9e8;
        font-weight: 500;
        font-style: normal;
        font-family: "SFCompactDisplay";
        background-image: url("../../assets/images/home/studio.png");
        background-size: 187px 26px;
        background-repeat: no-repeat;
        background-position-x: 50%;
      }
      .ant-spin {
        width: 100%;
        height: calc(100vh);
        position: fixed;
        top: 0;
        left: 0;
        background: #1e1e1e;
        opacity: 0.97;
        z-index: 100;
        .notContent {
          height: 170px;
          width: 656px;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -180px;
          transform: translateX(-50%);
        }
        .notRemark {
          text-align: center;
          color: #ffffff;
          font-family: "SFCompactDisplayLight";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
        }
        .notImgs {
          width: 100%;
          display: flex;
          justify-content: center;
          div {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background: #3d6bf5;
            margin-top: 40px;
            cursor: pointer;
            img {
              margin: 0 auto;
            }
          }
          div:hover {
            background-image: linear-gradient(to right, #26deea, #ba76ff, #fece1e);
          }
          .notOne {
            margin-right: 70px;
          }
          .img4 {
            width: 40px;
            height: 30px;
            margin-top: 14px;
            margin-left: 2px;
          }
          .img3 {
            width: 28px;
            height: 28px;
            margin-top: 15px;
            margin-left: 6px;
          }
        }
        .back {
          width: 180px;
          height: 50px;
          border: 1px solid #FECE1E;
          border-radius: 32px;
          color: #FECE1E;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 44px;
          margin: 0 auto;
          margin-top: 70px;
          cursor: pointer;
        }
      }
    }
  }
}
.hippoElse {
  min-width: 1520px;
  height: 100%;
  padding: 0 100px;
  background: rgb(18, 14, 29);
  position: relative;
}
.isTrue {
  width: 100%;
  height: 100vh;
}
